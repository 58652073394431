import React from 'react'
import Globalheadtabledata from '../../Component/Globalheadtabledata'
import "./Governence.css"
import { useNavigate } from 'react-router-dom'
const GovernanceManagement = () => {
    const navigate=useNavigate()
    return (
        <>
            <section className='techproviderpagesection'>
                <div className='cust-container'>
                    <div className=''>
                        <div className='giovernencretesypeterchbtnrchdivflx'>
                            <input className='typetoserchbtn' placeholder='Search Policy' />
                            <input className='typetoserchbtn' placeholder='Search Laws' />
                            <button className='runsimulatorbtn'>Run Simulator</button>
                        </div>
                        <Globalheadtabledata text="Digital Court System" btnname=" Return to Dashboard"   onClick={() => navigate("/Governenceprovider")}/>
                    </div>


                    <div className='techproviderriskpolicytabledivmain'>
                        <div className='policyandlowmakertablediv'>
                            <div className="policytbaledivmain">
                                <table className="governencepolicytablediv">
                                    <thead className="offenderdatatableheaddiv">
                                        <tr className="offenderdatatableheadtr">
                                            <td className="governencepolicytableheadtd">Policy Type


                                            </td>
                                            <td className="governencepolicytableheadtd">Policy Name


                                            </td>

                                           
                                            <td className="governencepolicytableheadtd">View Policy

                                            </td>


                                        </tr>
                                    </thead>
                                    <tbody className="offenderdatatablebodydiv">
                                        <tr className="techproviderrisktableebodytr">
                                            <td className="plicymanagemntdatatbleheadtdd">EUC Doomscrolling

                                            </td>
                                            <td className="plicymanagemntdatatbleheadtdd">
                                                DoomScroll v1.0

                                            </td>

                                           
                                            <td className="plicymanagemntdatatbleheadtdd">
                                                DoomScroll v1.0.docx

                                            </td>
                                           
                                        </tr>

                                        <tr className="techproviderrisktableebodytr">
                                            <td className="plicymanagemntdatatbleheadtdd">EUC Deepfakes


                                            </td>
                                            <td className="plicymanagemntdatatbleheadtdd">
                                                DeepFakes v.2.3

                                            </td>

                                           
                                            <td className="plicymanagemntdatatbleheadtdd">
                                                DeepFakes v.2.3.pdf


                                            </td>
                                          
                                        </tr>

                                        <tr className="techproviderrisktableebodytr">
                                            <td className="plicymanagemntdatatbleheadtdd">EUC Deepfakes


                                            </td>
                                            <td className="plicymanagemntdatatbleheadtdd">
                                                DeepFakes v.2.3

                                            </td>

                                         
                                            <td className="plicymanagemntdatatbleheadtdd">
                                                DeepFakes v.2.3.pdf


                                            </td>
                                         
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                            <div className='borderparticipant'></div>

                            <div className="lowmakerdivtbaledivmain">
                            <table className="governencepolicytablediv">
                                    <thead className="offenderdatatableheaddiv">
                                        <tr className="offenderdatatableheadtr">
                                            <td className="governencepolicytableheadtd">Law Maker



                                            </td>
                                            <td className="governencepolicytableheadtd">Law Name



                                            </td>

                                           
                                            <td className="governencepolicytableheadtd">View Law


                                            </td>


                                        </tr>
                                    </thead>
                                    <tbody className="offenderdatatablebodydiv">
                                        <tr className="techproviderrisktableebodytr">
                                            <td className="plicymanagemntdatatbleheadtdd">EUC 


                                            </td>
                                            <td className="plicymanagemntdatatbleheadtdd">
                                                DoomScroll v1.0

                                            </td>

                                           
                                            <td className="plicymanagemntdatatbleheadtdd">
                                            DoomScroll v1.0.docx


                                            </td>
                                           
                                        </tr>

                                        <tr className="techproviderrisktableebodytr">
                                            <td className="plicymanagemntdatatbleheadtdd">EUC 


                                            </td>
                                            <td className="plicymanagemntdatatbleheadtdd">
                                                DeepFakes v.2.3

                                            </td>

                                           
                                            <td className="plicymanagemntdatatbleheadtdd">
                                                DeepFakes v.2.3.pdf


                                            </td>
                                          
                                        </tr>

                                        <tr className="techproviderrisktableebodytr">
                                            <td className="plicymanagemntdatatbleheadtdd">EUC Deepfakes


                                            </td>
                                            <td className="plicymanagemntdatatbleheadtdd">
                                                DeepFakes v.2.3

                                            </td>

                                         
                                            <td className="plicymanagemntdatatbleheadtdd">
                                                DeepFakes v.2.3.pdf


                                            </td>
                                         
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>


                </div>
            </section>
        </>
    )
}

export default GovernanceManagement
