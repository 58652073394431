import React from 'react'
import Footer from './Footer'
import { Outlet } from 'react-router-dom'
import LandingpagerHeader from './LandingpagerHeader'

const LandingpageLayout = () => {
    return (
        <>
            <LandingpagerHeader />

            <Outlet />

            <Footer />
        </>
    )
}

export default LandingpageLayout
