import React from 'react'
import Returntypetosearch from '../../Component/Returntypetosearch'
const Compliencepolicyviolation = () => {
  return (
    <>
        <section className='offnedersectionmain'>
                <div className='cust-container'>
                 
                    <Returntypetosearch  placeholder="Type-to-Search-policy" returnpage="Return to Dashboard"  currentdate="  Current Date : September 4, 2024" to="/compliencemanagement"/>

                    <div className=''>
                        <p className='tableheadtxtpp'>Google</p>
                    </div>
                    <div className='tabledatallforaioffender'>
                        <table className='policyvilationdatatablediv'>

                            <thead className='offenderdatatableheaddiv'>
                                <tr className='offenderdatatableheadtr'>
                                    <td className='policyvilationdatatableheadtd '>
                                        Policy Violation

                                    </td>

                                    <td className='policyvilationdatatableheadtd '>
                                        Violation Frequency

                                    </td>


                                    <td className='policyvilationdatatableheadtd '>
                                        User Impact %
                                    </td>


                                    <td className='policyvilationdatatableheadtd '>
                                        Policy Maker

                                    </td>
                                </tr>
                            </thead>

                            <tbody className='offenderdatatablebodydiv'>
                                <tr className='offenderdatatablebodytr'>
                                    <th className='policyviolationdatadatatablebodytd'>
                                        Natural Language

                                    </th>

                                    <th className='policyviolationdatadatatablebodytd'>
                                        Daily
                                    </th>

                                    <th className='policyviolationdatadatatablebodytd'>
                                        100
                                    </th>

                                    <th className='policyviolationdatadatatablebodytd'>
                                        EUC
                                    </th>

                                </tr>

                                <tr className='offenderdatatablebodytr'>
                                    <th className='policyviolationdatadatatablebodytd'>
                                        fMRI Translations


                                    </th>

                                    <th className='policyviolationdatadatatablebodytd'>
                                        Daily
                                    </th>

                                    <th className='policyviolationdatadatatablebodytd'>
                                        100
                                    </th>

                                    <th className='policyviolationdatadatatablebodytd'>
                                        EUC
                                    </th>

                                </tr>

                                <tr className='offenderdatatablebodytr'>
                                    <th className='policyviolationdatadatatablebodytd'>
                                        WiFi Radio Signal (Omnipresent Surveillance)


                                    </th>

                                    <th className='policyviolationdatadatatablebodytd'>
                                        Daily
                                    </th>

                                    <th className='policyviolationdatadatatablebodytd'>
                                        100
                                    </th>

                                    <th className='policyviolationdatadatatablebodytd'>
                                        NATO, United Nations

                                    </th>

                                </tr>

                                <tr className='offenderdatatablebodytr'>
                                    <th className='policyviolationdatadatatablebodytd'>
                                        Code exploitation

                                    </th>

                                    <th className='policyviolationdatadatatablebodytd'>
                                        Daily
                                    </th>

                                    <th className='policyviolationdatadatatablebodytd'>
                                        100
                                    </th>

                                    <th className='policyviolationdatadatatablebodytd'>
                                        EUC
                                    </th>

                                </tr>


                                <tr className='offenderdatatablebodytr'>
                                    <th className='policyviolationdatadatatablebodytd'>
                                        GDP


                                    </th>

                                    <th className='policyviolationdatadatatablebodytd'>
                                        Daily
                                    </th>

                                    <th className='policyviolationdatadatatablebodytd'>
                                        100
                                    </th>

                                    <th className='policyviolationdatadatatablebodytd'>
                                        EUC, NATO, USA

                                    </th>

                                </tr>

                                <tr className='offenderdatatablebodytr'>
                                    <th className='policyviolationdatadatatablebodytd'>
                                        Children’s Identity
                                    </th>

                                    <th className='policyviolationdatadatatablebodytd'>
                                        Daily
                                    </th>

                                    <th className='policyviolationdatadatatablebodytd'>
                                        100
                                    </th>

                                    <th className='policyviolationdatadatatablebodytd'>
                                        EUC
                                    </th>

                                </tr>

                                <tr className='offenderdatatablebodytr'>
                                    <th className='policyviolationdatadatatablebodytd'>
                                        Theory of Mind


                                    </th>

                                    <th className='policyviolationdatadatatablebodytd'>
                                        Daily
                                    </th>

                                    <th className='policyviolationdatadatatablebodytd'>
                                        100
                                    </th>

                                    <th className='policyviolationdatadatatablebodytd'>
                                        NATO

                                    </th>

                                </tr>

                                <tr className='offenderdatatablebodytr'>
                                    <th className='policyviolationdatadatatablebodytd'>
                                        Elections
                                    </th>

                                    <th className='policyviolationdatadatatablebodytd'>
                                        Daily
                                    </th>

                                    <th className='policyviolationdatadatatablebodytd'>
                                        100
                                    </th>

                                    <th className='policyviolationdatadatatablebodytd'>
                                        USA

                                    </th>

                                </tr>


                                <tr className='offenderdatatablebodytr'>
                                    <th className='policyviolationdatadatatablebodytd'>
                                        Natural Language

                                    </th>

                                    <th className='policyviolationdatadatatablebodytd'>
                                        Daily
                                    </th>

                                    <th className='policyviolationdatadatatablebodytd'>
                                        100
                                    </th>

                                    <th className='policyviolationdatadatatablebodytd'>
                                        EUC
                                    </th>

                                </tr>

                                <tr className='offenderdatatablebodytr'>
                                    <th className='policyviolationdatadatatablebodytd'>
                                        fMRI Translations


                                    </th>

                                    <th className='policyviolationdatadatatablebodytd'>
                                        Daily
                                    </th>

                                    <th className='policyviolationdatadatatablebodytd'>
                                        100
                                    </th>

                                    <th className='policyviolationdatadatatablebodytd'>
                                        EUC
                                    </th>

                                </tr>

                                <tr className='offenderdatatablebodytr'>
                                    <th className='policyviolationdatadatatablebodytd'>
                                        WiFi Radio Signal (Omnipresent Surveillance)


                                    </th>

                                    <th className='policyviolationdatadatatablebodytd'>
                                        Daily
                                    </th>

                                    <th className='policyviolationdatadatatablebodytd'>
                                        100
                                    </th>

                                    <th className='policyviolationdatadatatablebodytd'>
                                        NATO, United Nations

                                    </th>

                                </tr>

                                <tr className='offenderdatatablebodytr'>
                                    <th className='policyviolationdatadatatablebodytd'>
                                        Code exploitation

                                    </th>

                                    <th className='policyviolationdatadatatablebodytd'>
                                        Daily
                                    </th>

                                    <th className='policyviolationdatadatatablebodytd'>
                                        100
                                    </th>

                                    <th className='policyviolationdatadatatablebodytd'>
                                        EUC
                                    </th>

                                </tr>


                                <tr className='offenderdatatablebodytr'>
                                    <th className='policyviolationdatadatatablebodytd'>
                                        GDP


                                    </th>

                                    <th className='policyviolationdatadatatablebodytd'>
                                        Daily
                                    </th>

                                    <th className='policyviolationdatadatatablebodytd'>
                                        100
                                    </th>

                                    <th className='policyviolationdatadatatablebodytd'>
                                        EUC, NATO, USA

                                    </th>

                                </tr>

                                <tr className='offenderdatatablebodytr'>
                                    <th className='policyviolationdatadatatablebodytd'>
                                        Children’s Identity
                                    </th>

                                    <th className='policyviolationdatadatatablebodytd'>
                                        Daily
                                    </th>

                                    <th className='policyviolationdatadatatablebodytd'>
                                        100
                                    </th>

                                    <th className='policyviolationdatadatatablebodytd'>
                                        EUC
                                    </th>

                                </tr>

                                <tr className='offenderdatatablebodytr'>
                                    <th className='policyviolationdatadatatablebodytd'>
                                        Theory of Mind


                                    </th>

                                    <th className='policyviolationdatadatatablebodytd'>
                                        Daily
                                    </th>

                                    <th className='policyviolationdatadatatablebodytd'>
                                        100
                                    </th>

                                    <th className='policyviolationdatadatatablebodytd'>
                                        NATO

                                    </th>

                                </tr>

                                <tr className='offenderdatatablebodytr'>
                                    <th className='policyviolationdatadatatablebodytd'>
                                        Elections
                                    </th>

                                    <th className='policyviolationdatadatatablebodytd'>
                                        Daily
                                    </th>

                                    <th className='policyviolationdatadatatablebodytd'>
                                        100
                                    </th>

                                    <th className='policyviolationdatadatatablebodytd'>
                                        USA

                                    </th>

                                </tr>



                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
    </>
  )
}

export default Compliencepolicyviolation
