import React from 'react'
import Returntypetosearch from '../../Component/Returntypetosearch'
import Globalviolationpolicytable from '../../Component/Globalviolationpolicytable'
const Complienceglobalviolationpolicy = () => {
  return (
    <>
       <section className='offnedersectionmain'>
                <div className='cust-container'>
                    <Returntypetosearch  placeholder="Type-to-Search-policy" returnpage="Return to Dashboard"  currentdate="  Current Date : September 4, 2024" to="/compliencemanagement"/>

                    <Globalviolationpolicytable />

                </div>
            </section>
    </>
  )
}

export default Complienceglobalviolationpolicy
