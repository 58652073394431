import React from 'react'
import { Link } from 'react-router-dom'

const Login = () => {
    return (
        <>
            <section className='registrationsection'>
                <div className='cust-container'>
                    <div className='registrationconentall'>
                        <p className='crtyiyyacnttxtp'>
                            Welcome Back
                        </p>

                        <div className='registarytonfrmdiv'>
                            <form>
                                <div className='regitartionfrminptmain'>
                                    <input type='text' className='regitartionfrminpt' placeholder='Email' />
                                </div>
                                <div className='regitartionfrminptmain'>
                                    <input type='number' className='regitartionfrminpt' placeholder='Password' />
                                    <div className='passwordshowhideicondiv'>
                                        <i class="fa-solid fa-eye"></i>
                                    </div>
                                    <p className='frgtytpasstxtp'>Forgot your password? <Link className="cliclforgtpaswrd">Click Here</Link></p>
                                </div>

                                <button className='lgninbtn'>
                                    Log In
                                </button>

                                <div className='SignUpbtnsaifeacctnbbtn'>
                                    <p className='saifeacntncttxtpp'>Need a S.A.I.F.E Account?
                                    </p>
                                    <button className='SignUpbtn'>
                                        Sign Up
                                    </button>
                                </div>


                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Login
