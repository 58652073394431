import React from 'react'
import { Link } from 'react-router-dom'

const Aihumanitypage = () => {
    return (
        <>
            <section className='aihumintymainsection'>
                <div className='aihumintymaintybg'>
                    <div className='cust-container'>
                        <div className='aihumintymainallcontent'>
                            <div className=''>
                                <p className='secutringtxtpp'>Securing AI for Humanity</p>
                            </div>
                            <div className=''>
                                <p className='welcomesaifetxtp'>WELCOME TO S.A.I.F.E
                                </p>
                            </div>
                            <div className='techpogocobtnmain'>
                                <Link className='techpogocobtn' to="/Techprovider">
                                    Tech Provider
                                </Link>
                                <Link className='techpogocobtn'  to="/Policyprovider">
                                Policy & Legal

                                </Link>
                                <Link className='techpogocobtn'  to="/Governenceprovider">
                                Governance

                                </Link>
                                <Link className='techpogocobtn'  to="/Complienceprovider">
                                Compliance

                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Aihumanitypage
