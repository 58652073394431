import React from 'react'
import Globalheadtabledata from '../../Component/Globalheadtabledata'
import { Input } from 'react-select/animated'
import { useNavigate } from 'react-router-dom'

const PolicyManagement = () => {
    const navigate=useNavigate()
    return (
        <>
            <section className='techproviderpagesection'>
                <div className='cust-container'>
                    <div className=''>
                        <div className='typetosetypeterchbtnrchdivflx'>
                            <input className='typetoserchbtn' placeholder='Type-to-search' />
                            <button className='runsimulatorbtn'>Run Simulator</button>
                        </div>
                        <Globalheadtabledata text="Policy Management" btnname=" Return to Dashboard" onClick={() => navigate("/Policyprovider")}  />
                    </div>


                    <div className='techproviderriskpolicytabledivmain'>
                        <table className="techproviderdigitalcourttablediv">
                            <thead className="offenderdatatableheaddiv">
                                <tr className="offenderdatatableheadtr">
                                    <td className="techproviderdigitalcourttableheadtd">Policy Type


                                    </td>
                                    <td className="techproviderdigitalcourttableheadtd">Policy Name


                                    </td>

                                    <td className="techproviderdigitalcourttableheadtd">Upload Policy



                                    </td>
                                    <td className="techproviderdigitalcourttableheadtd">View Policy

                                    </td>

                                    <td className="techproviderdigitalcourttableheadtd">

                                    </td>

                                </tr>
                            </thead>
                            <tbody className="offenderdatatablebodydiv">
                                <tr className="techproviderrisktableebodytr">
                                    <td className="plicymanagemntdatatbleheadtdd">EUC Doomscrolling

                                    </td>
                                    <td className="plicymanagemntdatatbleheadtdd">
                                        DoomScroll v1.0

                                    </td>

                                    <td className="plicymanagemntdatatbleheadtdd">
                                        <div className='browseimputdtfilediv'>
                                            Browse

                                            <input type='file'  className='browseimputdtfiledivinpt'/>
                                        </div>
                                    </td>
                                    <td className="plicymanagemntdatatbleheadtdd">
                                        DoomScroll v1.0.docx

                                    </td>
                                    <td className="plicymanagemntdatatbleheadtdd">
                                        Delete

                                    </td>
                                </tr>

                                <tr className="techproviderrisktableebodytr">
                                    <td className="plicymanagemntdatatbleheadtdd">EUC Deepfakes


                                    </td>
                                    <td className="plicymanagemntdatatbleheadtdd">
                                    DeepFakes v.2.3

                                    </td>

                                    <td className="plicymanagemntdatatbleheadtdd">
                                        <div className='browseimputdtfilediv'>
                                            Browse

                                            <input type='file'  className='browseimputdtfiledivinpt'/>
                                        </div>
                                    </td>
                                    <td className="plicymanagemntdatatbleheadtdd">
                                    DeepFakes v.2.3.pdf


                                    </td>
                                    <td className="plicymanagemntdatatbleheadtdd">
                                        Delete

                                    </td>
                                </tr>
                                
                                <tr className="techproviderrisktableebodytr">
                                    <td className="plicymanagemntdatatbleheadtdd">EUC Deepfakes


                                    </td>
                                    <td className="plicymanagemntdatatbleheadtdd">
                                    DeepFakes v.2.3

                                    </td>

                                    <td className="plicymanagemntdatatbleheadtdd">
                                        <div className='browseimputdtfilediv'>
                                            Browse

                                            <input type='file'  className='browseimputdtfiledivinpt'/>
                                        </div>
                                    </td>
                                    <td className="plicymanagemntdatatbleheadtdd">
                                    DeepFakes v.2.3.pdf


                                    </td>
                                    <td className="plicymanagemntdatatbleheadtdd">
                                        Delete

                                    </td>
                                </tr>

                            </tbody>
                        </table>

                    </div>


                </div>
            </section>
        </>
    )
}

export default PolicyManagement
