import React from 'react'
import globalwithouttext from "../../Images/globalwithouttext.png"
import { Link, useNavigate } from 'react-router-dom'
const Governenceprovidermainpage = () => {
    const navigate=useNavigate()
    const apliclickintegration =()=>{
        navigate("/governancemanagement")
    }
  return (
     <>
         <section className='techprovidermainsection'>
                <div className='cust-container'>
                    <div className='techproviderwelcomecontent'>
                        <p className='hellosaoifetxtpppmain'><span>Hello !</span> Welcome to S.A.I.F.E</p>
                    </div>

                    <p className='techproivedrlogintxtp'>Governance Provider logged in

                    </p>

                    <div className='clckheretxtcntntxtppandbtn'>
                        <p className='clckheretxtcntntxtpp'>Click here to connect</p>
                        <button className='allglbrlmbynmainspcl' onClick={apliclickintegration}>
                        Governance Management


                        </button>
                    </div>


                    <div className=''>
                        <p className='selectvalutetxtppp'>Select a Vault to continue</p>
                        <div className='fourglobtnmaindivkkkk'>
                            <div className='fourglobaldivbrnmainflx'>
                                <Link className='fourglobaldivbrmain' to="/governencepolicycomplienceoffenders">
                                    <div className='fourglobaldivbrmainbrdr'>
                                        <p className='fourglobaldivheadtrxtpp'>AI Compliance Offenders

                                        </p>
                                        <p className='fourglobaldivheadtrxratingtxtp'>21
                                        </p>
                                    </div>
                                </Link>
                                <Link className='fourglobaldivbrmain' to="/governenceriskandpolicy">
                                    <div className='fourglobaldivbrmainbrdr'>
                                        <p className='fourglobaldivheadtrxtpp'>Risk & Policy Breaches

                                        </p>
                                        <p className='fourglobaldivheadtrxratingtxtp'>2120
                                        </p>
                                    </div>
                                </Link>
                                <Link className='fourglobaldivbrmain' to="/governencedigitalcourt">
                                    <div className='fourglobaldivbrmainbrdr'>
                                        <p className='fourglobaldivheadtrxtpp'>Digital Court System

                                        </p>
                                        <p className='fourglobaldivheadtrxratingtxtp'>$10,500,000.00


                                        </p>
                                    </div>
                                </Link>

                                <Link className='fourglobaldivbrmain' to="/governencedigitalrecomendation">
                                    <div className='fourglobaldivbrmainbrdr'>
                                        <p className='fourglobaldivheadtrxtpp'>Recommendations

                                        </p>
                                        <p className='fourglobaldivheadtrxratingtxtp'> 22

                                        </p>
                                    </div>
                                </Link>

                                <Link className='fourglobaldivbrmain'  to="/governencelegalinfractions">
                                    <div className='fourglobaldivbrmainbrdr'>
                                        <p className='fourglobaldivheadtrxtpp'>Legal Infractions

                                        </p>
                                        <p className='fourglobaldivheadtrxratingtxtp'>577

                                        </p>
                                    </div>
                                </Link>

                                <Link className='fourglobaldivbrmain' to="/governencedigitaldocumentimport">
                                    <div className='fourglobaldivbrmainbrdr'>
                                        <p className='fourglobaldivheadtrxtpp'>Document Management

                                        </p>
                                        <p className='fourglobaldivheadtrxratingtxtp'>10

                                        </p>
                                    </div>
                                </Link>

                            </div>
                            <div className='globaldefenseimgmainfortch'>
                                <div className='globaldefenseimgmainglbl'>
                                    <img src={globalwithouttext} alt='...' />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  */}


                </div>
            </section>
      </>
  )
}

export default Governenceprovidermainpage
