import React, { useState } from 'react'
import Select from 'react-select';
const Documentmanagerimport = () => {
    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
    ];
    const [selectedOption, setSelectedOption] = useState(null);
    return (
        <>
            <div className='documnetmanagerdivmaian'>
                <button className='viewdocmntvalulktbtn'>
                    View Document Vault
                </button>

                <div className='SelfServicDocument'>
                    Self-Service Document Management
                </div>

                <div className='documnetmanagerdivfrm'>
                    <form>
                        <div className='documentmangerdivfrmmain'>
                            <p className='documentmangerdivlbltxtp'>Type</p>
                            <div className='documentmangerdivsclttdiv'>
                                <Select
                                    defaultValue={selectedOption}
                                    onChange={setSelectedOption}
                                    options={options}
                                    className='documentmangerdivscltslct'
                                />
                            </div>
                        </div>
                        <div className='documentmangerdivfrmmain'>
                            <p className='documentmangerdivlbltxtp'>File</p>
                            <div className='documentmangerdivsclttdiv'>
                                <div className='documentmangerdchsefilediv'>
                                    <p className='chosefilelstttxpp'>Choose File</p>
                                    <p className='nofilechsmrttxppp'>No file chosen</p>
                                    <div className='chosefiledivimgmain'>
                                        <input type="file" id="" name="myfile" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='importDownloadbtn'>
                            <button className='importbtn'>Import Document</button>
                            <button className='downloadbtn'>Download Template</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default Documentmanagerimport
