import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainLayout from './Layout/MainLayout';
import Home from './View/Home/Home';
import Offender from './View/Offenders/Offender';
import Riskcategory from './View/Offenders/Riskcategory';
import Policyviolation from './View/Offenders/Policyviolation';
import Lawinfraction from './View/Offenders/Lawinfraction';
import Globalpolicies from './View/Globalpolicies/Globalpolicies';
import Aihumanitymain from './View/Aihumanity/Aihumanitymain';
import Registration from './Authentication/Registration';
import Login from './Authentication/Login';
import Techprovider from './View/Techprovider/Techprovider';
import Techproviderapiintegration from './View/Techprovider/Techproviderapiintegration';
import Techproviderriskandpolicy from './View/Techprovider/Techproviderriskandpolicy';
import Techlegalinfraction from './View/Techprovider/Techlegalinfraction';
import Techproviderdigalcourt from './View/Techprovider/Techproviderdigalcourt';
import Globalrisk from './View/Globalpolicies/Globalrisk';
import Globalviolationpolicy from './View/Globalpolicies/Globalviolationpolicy';
import Globalpoliceslaw from './View/Globalpolicies/Globalpoliceslaw';
import Techdigitaldocumentimport from './View/Techprovider/Techdigitaldocumentimport';
import Techdigitalrecomendation from './View/Techprovider/Techdigitalrecomendation';
import Policyprovider from './View/Policyprovider/Policyprovider';
import Aipolicycomplienceoffenders from './View/Policyprovider/Aipolicycomplienceoffenders';
import Policyproviderriskandpolicy from './View/Policyprovider/Policyproviderriskandpolicy';
import Policyproviderlegalinfractions from './View/Policyprovider/Policyproviderlegalinfractions';
import Policyproviderdigitalcourt from './View/Policyprovider/Policyproviderdigitalcourt';
import Governenceprovider from './View/Governenceprovider/Governenceprovider';
import Complienceprovider from './View/Complienceprovider/Complienceprovider';
import GlobalPartcipant from './View/GlobalPartcipant/GlobalPartcipant';
import About from './View/About/About';
import PolicyManagement from './View/Policyprovider/PolicyManagement';
import GovernanceManagement from './View/Governenceprovider/GovernanceManagement';
import Policydigitaldocumentimport from './View/Policyprovider/Policydigitaldocumentimport';
import Policydigitalrecomendation from './View/Policyprovider/Policydigitalrecomendation';
import Governencecomplienceoffenders from './View/Governenceprovider/Governencecomplienceoffenders';
import Governenceriskandpolicy from './View/Governenceprovider/Governenceriskandpolicy';
import Governencedigitalcourt from './View/Governenceprovider/Governencedigitalcourt';
import Governencedigitalrecomendation from './View/Governenceprovider/Governencedigitalrecomendation';
import Governencelegalinfractions from './View/Governenceprovider/Governencelegalinfractions';
import Governencedigitaldocumentimport from './View/Governenceprovider/Governencedigitaldocumentimport';
import Compliencemanagement from './View/Complienceprovider/Compliencemanagement';
import Compliencepolicycomplienceoffenders from './View/Complienceprovider/Compliencepolicycomplienceoffenders';
import Complienceriskandpolicy from './View/Complienceprovider/Complienceriskandpolicy';
import Compliencelegalinfractions from './View/Complienceprovider/Compliencelegalinfractions';
import Compliencedigitalcourt from './View/Complienceprovider/Compliencedigitalcourt';
import Compliencedigitaldocumentimport from './View/Complienceprovider/Compliencedigitaldocumentimport';
import Compliencedigitalrecomendation from './View/Complienceprovider/Compliencedigitalrecomendation';
import Globalparticipantrisk from './View/GlobalPartcipant/Globalparticipantrisk';
import Globalparticipantviolationpolicy from './View/GlobalPartcipant/Globalparticipantviolationpolicy';
import Globalparticipantpolicylaws from './View/GlobalPartcipant/Globalparticipantpolicylaws';
import Complienceglobalviolationpolicy from './View/Complienceprovider/Complienceglobalviolationpolicy';
import Complienceglobalpolicylaws from './View/Complienceprovider/Complienceglobalpolicylaws';
import Compliencepolicyviolation from './View/Complienceprovider/Compliencepolicyviolation';
import Landingpage from './View/Landingpage/Landingpage';
import LandingpageLayout from './Layout/LandingpageLayout';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>


          <Route  element={<MainLayout />}>
            <Route path='/home' element={<Home />} />
            <Route path='/aboutus' element={<About />} />
            <Route path='/offender' element={<Offender />} />
            <Route path='/riskcategory' element={<Riskcategory />} />
            <Route path='/policyviolation' element={<Policyviolation />} />
            <Route path='/lawinfraction' element={<Lawinfraction />} />

            <Route path='/globalpolicies' element={<Globalpolicies />} />
            <Route path='/globalrisk' element={<Globalrisk />} />
            <Route path='/globalviolationpolicy' element={<Globalviolationpolicy />} />
            <Route path='/globalpolicylaws' element={<Globalpoliceslaw />} />

            <Route path="/globalPartcipant" element={<GlobalPartcipant />} />
            <Route path='/globalparticipantrisk' element={<Globalparticipantrisk />} />
            <Route path='/globalparticipantviolationpolicy' element={<Globalparticipantviolationpolicy />} />
            <Route path='/globalparticipantpolicylaws' element={<Globalparticipantpolicylaws />} />

            <Route path='/aihuminty' element={<Aihumanitymain />} />
            <Route path='/registration' element={<Registration />} />
            <Route path='/login' element={<Login />} />

            <Route path='/techprovider' element={<Techprovider />} />
            <Route path='/techproviderapiintegration' element={<Techproviderapiintegration />} />
            <Route path='/techproviderriskandpolicy' element={<Techproviderriskandpolicy />} />
            <Route path='/techlegalinfraction' element={<Techlegalinfraction />} />
            <Route path='/techdigitalcourt' element={<Techproviderdigalcourt />} />
            <Route path='/techdigitaldocumentimport' element={<Techdigitaldocumentimport />} />
            <Route path='/techdigitalrecomendation' element={<Techdigitalrecomendation />} />

            <Route path='/policyprovider' element={<Policyprovider />} />
            <Route path='/aipolicycomplienceoffenders' element={<Aipolicycomplienceoffenders />} />
            <Route path='/policyproviderriskandpolicy' element={<Policyproviderriskandpolicy />} />
            <Route path='/policyproviderlegalinfractions' element={<Policyproviderlegalinfractions />} />
            <Route path='/policyproviderdigitalcourt' element={<Policyproviderdigitalcourt />} />
            <Route path='/policydigitaldocumentimport' element={<Policydigitaldocumentimport />} />
            <Route path='/policydigitalrecomendation' element={<Policydigitalrecomendation />} />
            <Route path='/policymanagement' element={<PolicyManagement />} />

            <Route path='/governenceprovider' element={<Governenceprovider />} />
            <Route path="/governancemanagement" element={<GovernanceManagement />} />
            <Route path='/governencepolicycomplienceoffenders' element={<Governencecomplienceoffenders />} />
            <Route path='/governenceriskandpolicy' element={<Governenceriskandpolicy />} />
            <Route path='/governencelegalinfractions' element={<Governencelegalinfractions />} />
            <Route path='/governencedigitalcourt' element={<Governencedigitalcourt />} />
            <Route path='/governencedigitaldocumentimport' element={<Governencedigitaldocumentimport />} />
            <Route path='/governencedigitalrecomendation' element={<Governencedigitalrecomendation />} />



            <Route path='/complienceprovider' element={<Complienceprovider />} />
            <Route path="/compliencemanagement" element={<Compliencemanagement />} />
            <Route path='/complienceglobalviolationpolicy' element={<Complienceglobalviolationpolicy />} />
            <Route path='/complienceglobalpolicylaws' element={<Complienceglobalpolicylaws />} />
            <Route path='/compliencepolicyviolation' element={<Compliencepolicyviolation />} />
            <Route path='/compliencepolicycomplienceoffenders' element={<Compliencepolicycomplienceoffenders />} />
            <Route path='/complienceriskandpolicy' element={<Complienceriskandpolicy />} />
            <Route path='/compliencelegalinfractions' element={<Compliencelegalinfractions />} />
            <Route path='/compliencedigitalcourt' element={<Compliencedigitalcourt />} />
            <Route path='/compliencedigitaldocumentimport' element={<Compliencedigitaldocumentimport />} />
            <Route path='/compliencedigitalrecomendation' element={<Compliencedigitalrecomendation />} />



          </Route>

          <Route  element={<LandingpageLayout />}>
            <Route path='/' element={<Landingpage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
