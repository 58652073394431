import React from 'react'
import landingpagelogo from "../../src/Images/landingpagelogo.png"
import { Link } from 'react-router-dom'
const LandingpagerHeader = () => {
  return (
      <>
        <section className='landingpageheader'>
            <div className='cust-container '>
            <div className='logo-menudivmain'>
                        <Link className='logolnk' to="/home">
                            <figure className='logolnkimagelanfdibg'>
                                <img src={landingpagelogo} alt='logo' />
                            </figure>
                        </Link>
                        <div className='alllinddivflxlandigf'>
                          

                            <ul className='allnewlinkdivul'>
                                <li className='allnewlinkdivli'>
                                    <select className="translatecustom">
                                        <option value="spanish">EN</option>
                                        <option value="eng">ES</option>
                                    </select>
                                    <span class="borderrightcustom"></span>
                                </li>
                                <li className='allnewlinkdivli'>
                                    <Link className='lgnbtn' to="/login">Log In</Link>
                                </li>

                           

                                <li className='headerspcbtnli'>
                                    <Link className='offwnderbtn' to="/aihuminty"> Offender Registry
                                    </Link>
                                </li>

                            </ul>
                        </div>

                        {/* <div className='resbar' >
                            <i className="fa-solid fa-bars"></i>
                        </div> */}
                    </div>
            </div>
        </section>
      </>
  )
}

export default LandingpagerHeader
