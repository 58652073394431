import React from 'react'
import Globalheadtabledata from '../../Component/Globalheadtabledata'
import { useNavigate } from 'react-router-dom'

const Techproviderriskandpolicy = () => {
    const navigate=useNavigate()
    return (
        <>
            <section className='techproviderpagesection'>
                <div className='cust-container'>
                    <div className=''>
                        <input className='typetoserchbtn' placeholder='Type-to-search' />
                        <Globalheadtabledata text="Risk & Policy Breaches" btnname=" Return to Dashboard" onClick={()=>navigate("/techprovider")} />
                    </div>

                    <div className='techproviderriskpolicytabledivmain'>
                        <table className="techproviderriskpolicytablediv">
                            <thead className="offenderdatatableheaddiv">
                                <tr className="offenderdatatableheadtr">
                                    <td className="techproviderrisktableheadtd">Risk Category
                                    </td>
                                    <td className="techproviderrisktableheadtd">Policy Violations
                                    </td>

                                </tr>
                            </thead>
                            <tbody className="offenderdatatablebodydiv">
                                <tr className="techproviderrisktableebodytr">
                                    <td className="techproviderdatatablebodytd">Information Overload
                                    </td>
                                    <td className="techproviderdatatablebodytd">
                                        12
                                    </td>
                                </tr>
                               
                                <tr className="techproviderrisktableebodytr">
                                    <td className="techproviderdatatablebodytd">Information Overload
                                    </td>
                                    <td className="techproviderdatatablebodytd">
                                        12
                                    </td>
                                </tr>

                                <tr className="techproviderrisktableebodytr">
                                    <td className="techproviderdatatablebodytd">Addiction

                                    </td>
                                    <td className="techproviderdatatablebodytd">
                                    0

                                    </td>
                                </tr>


                                <tr className="techproviderrisktableebodytr">
                                    <td className="techproviderdatatablebodytd">Doomscrolling

                                    </td>
                                    <td className="techproviderdatatablebodytd">
                                    7

                                    </td>
                                </tr>


                                <tr className="techproviderrisktableebodytr">
                                    <td className="techproviderdatatablebodytd">Inluencer Culture

                                    </td>
                                    <td className="techproviderdatatablebodytd">
                                    0

                                    </td>
                                </tr>


                                <tr className="techproviderrisktableebodytr">
                                    <td className="techproviderdatatablebodytd">Sexualization of Kids

                                    </td>
                                    <td className="techproviderdatatablebodytd">
                                    300,000

                                    </td>
                                </tr>


                                <tr className="techproviderrisktableebodytr">
                                    <td className="techproviderdatatablebodytd">Qanon

                                    </td>
                                    <td className="techproviderdatatablebodytd">
                                    117

                                    </td>
                                </tr>


                                <tr className="techproviderrisktableebodytr">
                                    <td className="techproviderdatatablebodytd">Shortened Attention Spans

                                    </td>
                                    <td className="techproviderdatatablebodytd">
                                    1,345,000

                                    </td>
                                </tr>

                                <tr className="techproviderrisktableebodytr">
                                    <td className="techproviderdatatablebodytd">Polarization

                                    </td>
                                    <td className="techproviderdatatablebodytd">
                                    38

                                    </td>
                                </tr>
                                <tr className="techproviderrisktableebodytr">
                                    <td className="techproviderdatatablebodytd">Bots, DeepFakes
                                    </td>
                                    <td className="techproviderdatatablebodytd">
                                    400,223

                                    </td>
                                </tr>
                                <tr className="techproviderrisktableebodytr">
                                    <td className="techproviderdatatablebodytd">Cult factories

                                    </td>
                                    <td className="techproviderdatatablebodytd">
                                    3

                                    </td>
                                </tr>
                                <tr className="techproviderrisktableebodytr">
                                    <td className="techproviderdatatablebodytd">Fake News

                                    </td>
                                    <td className="techproviderdatatablebodytd">
                                    50,000,000

                                    </td>
                                </tr>
                                <tr className="techproviderrisktableebodytr">
                                    <td className="techproviderdatatablebodytd">Breakdown of Democracy

                                    </td>
                                    <td className="techproviderdatatablebodytd">
                                        3
                                    </td>
                                </tr>
                              
                            </tbody>
                        </table>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Techproviderriskandpolicy
