import React from 'react'
import Globalheadtabledata from '../../Component/Globalheadtabledata'
import { useNavigate } from 'react-router-dom';
const Governencedigitalcourt = () => {
    const navigate=useNavigate();
  return (
 <>
    <section className='techproviderpagesection'>
                <div className='cust-container'>
                    <div className=''>
                        <div className='typetosetypeterchbtnrchdivflx'>
                            <button className='typetoserchbtn'>Type-to-search</button>
                            <button className='runsimulatorbtn'>Run Simulator</button>
                        </div>
                        <Globalheadtabledata text="Digital Court System" btnname=" Return to Dashboard"  onClick={() => navigate("/governenceprovider")} />
                    </div>

                    <div className='techproviderriskpolicytabledivmain'>
                        <table className="techproviderdigitalcourttablediv">
                            <thead className="offenderdatatableheaddiv">
                                <tr className="offenderdatatableheadtr">
                                    <td className="techproviderdigitalcourttableheadtd">Law Category

                                    </td>
                                    <td className="techproviderdigitalcourttableheadtd">Law Violations

                                    </td>

                                    <td className="techproviderdigitalcourttableheadtd">Fines


                                    </td>
                                    <td className="techproviderdigitalcourttableheadtd">Indictments


                                    </td>
                                    <td className="techproviderdigitalcourttableheadtd">AI Violators


                                    </td>

                                </tr>
                            </thead>
                            <tbody className="offenderdatatablebodydiv">
                                <tr className="techproviderrisktableebodytr">
                                    <td className="techproviderdigitalcourtdatatablebodytd">Information Overload
                                    </td>
                                    <td className="techproviderdigitalcourtdatatablebodytd">
                                        12
                                    </td>

                                    <td className="techproviderdigitalcourtdatatablebodytd">$100,000

                                    </td>
                                    <td className="techproviderdigitalcourtdatatablebodytd">
                                        1
                                    </td>
                                    <td className="techproviderdigitalcourtdatatablebodytd">
                                        3
                                    </td>
                                </tr>

                                <tr className="techproviderrisktableebodytr">
                                    <td className="techproviderdigitalcourtdatatablebodytd">Information Overload
                                    </td>
                                    <td className="techproviderdigitalcourtdatatablebodytd">
                                        12
                                    </td>

                                    <td className="techproviderdigitalcourtdatatablebodytd">$100,000

                                    </td>
                                    <td className="techproviderdigitalcourtdatatablebodytd">
                                        1
                                    </td>
                                    <td className="techproviderdigitalcourtdatatablebodytd">
                                        4
                                    </td>
                                </tr>

                                <tr className="techproviderrisktableebodytr">
                                    <td className="techproviderdigitalcourtdatatablebodytd">Addiction

                                    </td>
                                    <td className="techproviderdigitalcourtdatatablebodytd">
                                        0

                                    </td>

                                    <td className="techproviderdigitalcourtdatatablebodytd">$100,000

                                    </td>
                                    <td className="techproviderdigitalcourtdatatablebodytd">
                                        1
                                    </td>
                                    <td className="techproviderdigitalcourtdatatablebodytd">
                                        3
                                    </td>
                                </tr>


                                <tr className="techproviderrisktableebodytr">
                                    <td className="techproviderdigitalcourtdatatablebodytd">Doomscrolling

                                    </td>
                                    <td className="techproviderdigitalcourtdatatablebodytd">
                                        7

                                    </td>

                                    <td className="techproviderdigitalcourtdatatablebodytd">$100,000

                                    </td>
                                    <td className="techproviderdigitalcourtdatatablebodytd">
                                        1
                                    </td>
                                    <td className="techproviderdigitalcourtdatatablebodytd">
                                        7
                                    </td>
                                </tr>


                                <tr className="techproviderrisktableebodytr">
                                    <td className="techproviderdigitalcourtdatatablebodytd">Inluencer Culture

                                    </td>
                                    <td className="techproviderdigitalcourtdatatablebodytd">
                                        0

                                    </td>

                                    <td className="techproviderdigitalcourtdatatablebodytd">$100,000

                                    </td>
                                    <td className="techproviderdigitalcourtdatatablebodytd">
                                        1
                                    </td>
                                    <td className="techproviderdigitalcourtdatatablebodytd">
                                        8
                                    </td>
                                </tr>


                                <tr className="techproviderrisktableebodytr">
                                    <td className="techproviderdigitalcourtdatatablebodytd">Sexualization of Kids

                                    </td>
                                    <td className="techproviderdigitalcourtdatatablebodytd">
                                        300,000

                                    </td>

                                    <td className="techproviderdigitalcourtdatatablebodytd">$100,000

                                    </td>
                                    <td className="techproviderdigitalcourtdatatablebodytd">
                                        1
                                    </td>

                                    <td className="techproviderdigitalcourtdatatablebodytd">
                                        9
                                    </td>
                                </tr>


                                <tr className="techproviderrisktableebodytr">
                                    <td className="techproviderdigitalcourtdatatablebodytd">Qanon

                                    </td>
                                    <td className="techproviderdigitalcourtdatatablebodytd">
                                        117

                                    </td>

                                    <td className="techproviderdigitalcourtdatatablebodytd">$100,000

                                    </td>
                                    <td className="techproviderdigitalcourtdatatablebodytd">
                                        1
                                    </td>
                                    <td className="techproviderdigitalcourtdatatablebodytd">
                                        3
                                    </td>
                                </tr>


                                <tr className="techproviderrisktableebodytr">
                                    <td className="techproviderdigitalcourtdatatablebodytd">Shortened Attention Spans

                                    </td>
                                    <td className="techproviderdigitalcourtdatatablebodytd">
                                        1,345,000

                                    </td>

                                    <td className="techproviderdigitalcourtdatatablebodytd">$100,000

                                    </td>
                                    <td className="techproviderdigitalcourtdatatablebodytd">
                                        1
                                    </td>
                                    <td className="techproviderdigitalcourtdatatablebodytd">
                                        4
                                    </td>
                                </tr>

                                <tr className="techproviderrisktableebodytr">
                                    <td className="techproviderdigitalcourtdatatablebodytd">Polarization

                                    </td>
                                    <td className="techproviderdigitalcourtdatatablebodytd">
                                        38

                                    </td>

                                    <td className="techproviderdigitalcourtdatatablebodytd">$100,000

                                    </td>
                                    <td className="techproviderdigitalcourtdatatablebodytd">
                                        1
                                    </td>
                                    <td className="techproviderdigitalcourtdatatablebodytd">
                                        3
                                    </td>
                                </tr>
                                <tr className="techproviderrisktableebodytr">
                                    <td className="techproviderdigitalcourtdatatablebodytd">Bots, DeepFakes
                                    </td>
                                    <td className="techproviderdigitalcourtdatatablebodytd">
                                        400,223

                                    </td>
                                    <td className="techproviderdigitalcourtdatatablebodytd">$100,000

                                    </td>
                                    <td className="techproviderdigitalcourtdatatablebodytd">
                                        1
                                    </td>
                                    <td className="techproviderdigitalcourtdatatablebodytd">
                                        5
                                    </td>
                                </tr>
                                <tr className="techproviderrisktableebodytr">
                                    <td className="techproviderdigitalcourtdatatablebodytd">Cult factories

                                    </td>
                                    <td className="techproviderdigitalcourtdatatablebodytd">
                                        3

                                    </td>

                                    <td className="techproviderdigitalcourtdatatablebodytd">$100,000

                                    </td>
                                    <td className="techproviderdigitalcourtdatatablebodytd">
                                        1
                                    </td>
                                    <td className="techproviderdigitalcourtdatatablebodytd">
                                        5
                                    </td>
                                </tr>
                                <tr className="techproviderrisktableebodytr">
                                    <td className="techproviderdigitalcourtdatatablebodytd">Fake News

                                    </td>
                                    <td className="techproviderdigitalcourtdatatablebodytd">
                                        50,000,000

                                    </td>

                                    <td className="techproviderdigitalcourtdatatablebodytd">$100,000

                                    </td>
                                    <td className="techproviderdigitalcourtdatatablebodytd">
                                        1
                                    </td>
                                    <td className="techproviderdigitalcourtdatatablebodytd">
                                        8
                                    </td>
                                </tr>
                                <tr className="techproviderrisktableebodytr">
                                    <td className="techproviderdigitalcourtdatatablebodytd">Breakdown of Democracy

                                    </td>
                                    <td className="techproviderdigitalcourtdatatablebodytd">
                                        3
                                    </td>

                                    <td className="techproviderdigitalcourtdatatablebodytd">$100,000

                                    </td>
                                    <td className="techproviderdigitalcourtdatatablebodytd">
                                        1
                                    </td>
                                    <td className="techproviderdigitalcourtdatatablebodytd">
                                        9
                                    </td>
                                </tr>

                            </tbody>
                        </table>

                    </div>
                </div>
            </section>
 </>
  )
}

export default Governencedigitalcourt
