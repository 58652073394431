import React from 'react'
import "./Authentication.css"
const Registration = () => {
    return (
        <>
            <section className='registrationsection'>
                <div className='cust-container'>
                    <div className='registrationconentall'>
                        <p className='crtyiyyacnttxtp'>
                            Create your account!
                        </p>

                        <div className='registarytonfrmdiv'>
                            <form>
                                <div className='regitartionfrminptmain'>
                                    <input type='text' className='regitartionfrminpt' placeholder='Email' />
                                </div>
                                <div className='regitartionfrminptmain'>
                                    <input type='number' className='regitartionfrminpt' placeholder='Password' />
                                    <div className='passwordshowhideicondiv'>
                                        <i class="fa-solid fa-eye"></i>
                                    </div>
                                </div>
                                <div className='regitartionfrminptmain'>
                                    <input type='number' className='regitartionfrminpt' placeholder='Confirm Password' />
                                    <div className='passwordshowhideicondiv'>
                                        <i class="fa-solid fa-eye"></i>
                                    </div>
                                </div>
                                <div className='regitartionfrminptcheckdiv'>
                                    <div className='regitartionfrmcheckbox'>
                                        <input type="checkbox" id="" name="" value="" className='regitartionfrmchecinpt' />
                                        <label for="" className='regitartionfrmchecinpttxtp'> Technology
                                        </label>
                                    </div>
                                    <div className='regitartionfrmcheckbox'>
                                        <input type="checkbox" id="" name="" value="" className='regitartionfrmchecinpt' />
                                        <label for="" className='regitartionfrmchecinpttxtp'> Policy & Legal

                                        </label>
                                    </div>
                                    <div className='regitartionfrmcheckbox'>
                                        <input type="checkbox" id="" name="" value="" className='regitartionfrmchecinpt' />
                                        <label for="" className='regitartionfrmchecinpttxtp'> Governance

                                        </label>
                                    </div>
                                    <div className='regitartionfrmcheckbox'>
                                        <input type="checkbox" id="" name="" value="" className='regitartionfrmchecinpt' />
                                        <label for="" className='regitartionfrmchecinpttxtp'> Compliance
                                        </label>
                                    </div>
                                </div>
                                <div className='regitartionfrminptmain'>
                                    <input type='number' className='regitartionfrminpt' placeholder='Organization Code' />

                                </div>
                                <div className='regitartionfrminptcheckdiv'>
                                    <div className='regitartionfrmcheckbox'>
                                        <input type="checkbox" id="" name="" value="" className='regitartionfrmchecinpt' />
                                        <label for="" className='regitsttrionaccpttxtpaccept'>Accept global AI policies, terms of service, and compliance conditions

                                        </label>
                                    </div>
                                    
                                </div>

                                <button className='registerbtn'>
                                    Register
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Registration
