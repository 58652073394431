import React from 'react'
import Globalheadtabledata from '../../Component/Globalheadtabledata'
import Documentmanagerimport from '../../Component/Documentmanagerimport'
import { useNavigate } from 'react-router-dom'
const Compliencedigitaldocumentimport = () => {
    const navigate=useNavigate()
  return (
    <>
          <section className='techproviderpagesection'>
                <div className='cust-container'>
                    <div className=''>
                    {/* <input className='typetoserchbtn' placeholder='Type-to-search' /> */}
                        <Globalheadtabledata text="Document Manager – Import " btnname=" Return to Dashboard"  onClick={()=>navigate("/complienceprovider")} />
                    </div>

                    <div className='docmnegaerimportdiv'>
                         <Documentmanagerimport/>
                    </div>
                </div>
            </section>
    </>
  )
}

export default Compliencedigitaldocumentimport
