import React from 'react'
import footerlogo from "../../src/Images/footerlogo.png"
import { Link } from 'react-router-dom';
// import globaldefense from "../../src/Images/globaldefense.png"
const Footer = () => {
    return (
        <>

            {/* <div className='globaldefenseimgmainfortch'>
                <div className='globaldefenseimgmainglbl'>
                    <img src={globaldefense} alt='...' />
                </div>
            </div> */}

            <section className='foootermain'>
                <div className='fottercustcontainer'>
                    <div className='footerlogosocilalinkflx'>
                        <Link className='logolnk' to="/">
                            <figure className='logolnkimage'>
                                <img src={footerlogo} alt='logo' />
                            </figure>
                        </Link>

                        <div className='fotterbrdrlinksocialdiv'>
                            <div className='fotterbrdrmain'>
                                <span className='fotterbrdrmainspan'>

                                </span>
                            </div>
                            <ul className='footersocilaiocndiv'>
                                <li className='footersocilaiocndivli'>
                                    <Link className='footersocilaiocnsoclnlk' to="/">
                                        <i className="fa-brands fa-facebook-f"></i>
                                    </Link>
                                </li>
                                <li className='footersocilaiocndivli'>
                                    <Link className='footersocilaiocnsoclnlk' to="/">
                                        <i className="fa-brands fa-twitter"></i>
                                    </Link>
                                </li>
                                <li className='footersocilaiocndivli'>
                                    <Link className='footersocilaiocnsoclnlk' to="/">
                                        <i className="fa-brands fa-youtube"></i>
                                    </Link>
                                </li>
                                <li className='footersocilaiocndivli'>
                                    <Link className='footersocilaiocnsoclnlk' to="/">
                                        <i class="fa-brands fa-instagram"></i>
                                    </Link>
                                </li>

                            </ul>
                        </div>

                    </div>

                    <div className='fotterpagelinkdivulmain'>
                        <ul className='fotterpagelinkdivul'>
                            <li className='fotterpagelinkdivli'>
                                <Link className='fotterpagelinkdivlnlttxp' to="/">
                                    Home
                                </Link>
                            </li>
                            <li className='fotterpagelinkdivli'>
                                <Link className='fotterpagelinkdivlnlttxp' to="/">
                                    Offenders
                                </Link>
                            </li>
                            <li className='fotterpagelinkdivli'>
                                <Link className='fotterpagelinkdivlnlttxp' to="/">
                                    Global Policies
                                </Link>
                            </li>
                            <li className='fotterpagelinkdivli'>
                                <Link className='fotterpagelinkdivlnlttxp' to="/">
                                    Global Participants
                                </Link>
                            </li>
                            <li className='fotterpagelinkdivli'>
                                <Link className='fotterpagelinkdivlnlttxp' to="/">
                                    About Us
                                </Link>
                            </li>

                            <li className='fotterpagelinkdivli'>
                                <Link className='fotterpagelinkdivlnlttxp' to="/">
                                    Delete Account
                                </Link>
                            </li>

                        </ul>
                    </div>
                    <div className='fotterpagelinkdivulmain'>
                        <ul className='fotterpagelinkdivulresponsive'>
                            <li className='fotterpagelinkdivli'>
                                <Link className='fotterpagelinkdivlnlttxp' to="/">
                                    Home
                                </Link>
                            </li>
                            <li className='fotterpagelinkdivli'>
                                <Link className='fotterpagelinkdivlnlttxp' to="/">
                                    Offenders
                                </Link>
                            </li>
                            <li className='fotterpagelinkdivli'>
                                <Link className='fotterpagelinkdivlnlttxp' to="/">
                                    Global Policies
                                </Link>
                            </li>
                            <li className='fotterpagelinkdivli'>
                                <Link className='fotterpagelinkdivlnlttxp' to="/">
                                    Global Participants
                                </Link>
                            </li>
                            <li className='fotterpagelinkdivli'>
                                <Link className='fotterpagelinkdivlnlttxp' to="/">
                                    About Us
                                </Link>
                            </li>

                            <li className='fotterpagelinkdivli'>
                                <Link className='fotterpagelinkdivlnlttxp' to="/">
                                    Delete Account
                                </Link>
                            </li>

                        </ul>
                    </div>

                    <div className='saifespclllnik'>
                        <Link className='saifespclllniktxtp' to="/">
                            support@saife-agi.com
                        </Link>
                    </div>

                    <div className='saifespclllnik'>
                        <Link className='saifespclllniktxtp' to="/">
                            Login
                        </Link>
                    </div>

                    <div className='privacycopyrightdiv'>
                        <Link className='privacycopyrightxtplnk' >Privacy Policy</Link>
                        <p className='privacycopyrightxtp'>Copyright © 2024 S.A.I.F.E
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Footer
