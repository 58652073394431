import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const Globalpoliciespage = () => {

    const navigate=useNavigate()

    const globalriskclick =()=>{
        navigate("/globalrisk");
    }

    
    const globalpolicyclick =()=>{
        navigate("/globalviolationpolicy");
    }

    const globallawsclick =()=>{
        navigate("/globalpolicylaws");
    }

    const aioffendersdata = [
        {
            Policyproviders: " EUC",
            KnownRisks: "112",
            polices: "32",
            PolicyViolations: "2,422",
            Laws: "12",
            ComplianceRating: "733",
        },
        {
            Policyproviders: " United Nations",
            KnownRisks: "717",
            polices: "27",
            PolicyViolations: "509",
            Laws: "21",
            ComplianceRating: "294",
        },
        {
            Policyproviders: " NATO",
            KnownRisks: "401",
            polices: "19",
            PolicyViolations: "7",
            Laws: "9",
            ComplianceRating: "5",
        },
    ]
    return (
        <>
            <section className='offnedersectionmain'>
                <div className='cust-container'>
                    <div className='currentandtypesrchntndiv'>
                        <p className='datacurrenttxtp'>
                            Current Date : September 4, 2024
                        </p>
                        <div className='typesrchbtndiv'>
                            <input type='text' placeholder=' Type-to-Search-Offenders' className='typesrchbtn' />
                        </div>
                    </div>

                    <div className=''>
                        <p className='tableheadtxtpp'>Global Policies
                        </p>
                    </div>

                    <div className='tabledatallforaioffender'>
                        <table className='offenderdatatablediv'>
                            <thead className='offenderdatatableheaddiv'>
                                <tr className='offenderdatatableheadtr'>
                                    <td className='offenderdatatableheadtd'>
                                        Policy Providers
                                    </td>

                                    <td className='offenderdatatableheadtd'>
                                        Known Risks
                                    </td>


                                    <td className='offenderdatatableheadtd'>
                                        # of Policies
                                    </td>


                                    <td className='offenderdatatableheadtd'>
                                        Policy Violations
                                    </td>

                                    <td className='offenderdatatableheadtd'>
                                        # of Laws
                                    </td>

                                    <td className='offenderdatatableheadtd'>
                                        Law Infractions

                                    </td>
                                </tr>
                            </thead>
                            <tbody className='offenderdatatablebodydiv'>
                                {aioffendersdata.map((item, index) => {
                                    return (
                                        <tr className='offenderdatatablebodytr'>
                                            <td className='offenderdatatablebodytd'>
                                                
                                                    {item.Policyproviders}
                                                
                                            </td>

                                            <td className='offenderdatatablebodytd' onClick={globalriskclick}>

                                                {item.KnownRisks}
                                                <span className='arrowupoffenderdiv'>
                                                    <i className="fa-solid fa-caret-up"></i>
                                                </span>

                                            </td>


                                            <td className='offenderdatatablebodytd' >
                                                {item.polices}
                                                {/* <span className='arrowupoffenderdiv'>
                                                    <i className="fa-solid fa-caret-up"></i>
                                                </span> */}
                                            </td>


                                            <td className='offenderdatatablebodytd' onClick={globalpolicyclick} >
                                                {item.PolicyViolations}
                                                <span className='arrowupoffenderdiv'>
                                                    <i className="fa-solid fa-caret-up"></i>
                                                </span>
                                            </td>

                                            <td className='offenderdatatablebodytd'>
                                                {item.Laws}

                                            </td>


                                            <td className='offenderdatatablebodytd' onClick={globallawsclick}>
                                                {item.ComplianceRating}
                                                <span className='arrowupoffenderdiv'>
                                                    <i className="fa-solid fa-caret-up"></i>
                                                </span>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Globalpoliciespage
