import React from 'react'
import Techproviderapipage from './Techproviderapipage'

const Techproviderapiintegration = () => {
    return (
        <>
           <Techproviderapipage/>
        </>
    )
}

export default Techproviderapiintegration
