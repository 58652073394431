import React from 'react'
import globalwithouttext from "../../Images/globalwithouttext.png"
import { Link, useNavigate } from 'react-router-dom'
const Techprovidermainpage = () => {
    const navigate=useNavigate()
    const apliclickintegration =()=>{
        navigate("/techproviderapiintegration")
    }
    return (
        <>
            <section className='techprovidermainsection'>
                <div className='cust-container'>
                    <div className='techproviderwelcomecontent'>
                        <p className='hellosaoifetxtpppmain'><span>Hello !</span> Welcome to S.A.I.F.E</p>
                    </div>

                    <p className='techproivedrlogintxtp'>Tech Provider logged in</p>

                    <div className='clckheretxtcntntxtppandbtn'>
                        <p className='clckheretxtcntntxtpp'>Click here to connect</p>
                        <button className='allglbrlmbynmainspcl' onClick={apliclickintegration}>
                            API Integration
                        </button>
                    </div>


                    <div className=''>
                        <p className='selectvalutetxtppp'>Select a Vault to continue</p>
                        <div className='fourglobtnmaindivkkkk'>
                            <div className='fourglobaldivbrnmainflx'>
                                <div className='fourglobaldivbrmain'>
                                    <div className='fourglobaldivbrmainbrdr'>
                                        <p className='fourglobaldivheadtrxtpp'>AI Compliance Rating
                                        </p>
                                        <p className='fourglobaldivheadtrxratingtxtp'>6.9
                                        </p>
                                    </div>
                                </div>
                                <Link className='fourglobaldivbrmain' to="/techproviderriskandpolicy">
                                    <div className='fourglobaldivbrmainbrdr' >
                                        <p className='fourglobaldivheadtrxtpp'>Risk & Policy Breaches

                                        </p>
                                        <p className='fourglobaldivheadtrxratingtxtp'>14
                                        </p>
                                    </div>
                                </Link>
                                <Link className='fourglobaldivbrmain' to="/techdigitalcourt">
                                    <div className='fourglobaldivbrmainbrdr'>
                                        <p className='fourglobaldivheadtrxtpp'>Digital Court System

                                        </p>
                                        <p className='fourglobaldivheadtrxratingtxtp'>$1,500,000.00

                                        </p>
                                    </div>
                                </Link>
                                <Link className='fourglobaldivbrmain' to="/techdigitalrecomendation">
                                    <div className='fourglobaldivbrmainbrdr'>
                                        <p className='fourglobaldivheadtrxtpp'>Recommendations

                                        </p>
                                        <p className='fourglobaldivheadtrxratingtxtp'>Learning

                                        </p>
                                    </div>
                                </Link>

                                <Link className='fourglobaldivbrmain' to="/techlegalinfraction">
                                    <div className='fourglobaldivbrmainbrdr'>
                                        <p className='fourglobaldivheadtrxtpp'>Legal Infractions

                                        </p>
                                        <p className='fourglobaldivheadtrxratingtxtp'>15

                                        </p>
                                    </div>
                                </Link>

                                <Link className='fourglobaldivbrmain' to="/techdigitaldocumentimport">
                                    <div className='fourglobaldivbrmainbrdr'>
                                        <p className='fourglobaldivheadtrxtpp'>Document Management

                                        </p>
                                        <p className='fourglobaldivheadtrxratingtxtp'>10

                                        </p>
                                    </div>
                                </Link>

                            </div>
                            <div className='globaldefenseimgmainfortch'>
                                <div className='globaldefenseimgmainglbl'>
                                    <img src={globalwithouttext} alt='...' />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  */}


                </div>
            </section>
        </>
    )
}

export default Techprovidermainpage
