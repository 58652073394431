import React from 'react'
import Aihumanitypage from './Aihumanitypage'
import "./Aihuminty.css"
const Aihumanitymain = () => {
  return (
     <>
         <Aihumanitypage/>
     
     </>
  )
}

export default Aihumanitymain
