import React from 'react'

const Globalheadtabledata = ({text,btnname,onClick}) => {
    return (
        <>
            <div className='globalheadtadledatamain'>
                <div className='globalheadtadledatadivflx'>
                    <p className='apiintegrationtxtpp'>
                           {text}
                    </p>

                    <button className='dshbtnreturn' onClick={onClick}>
                         {btnname}
                    </button>
                </div>
            </div>
        </>
    )
}

export default Globalheadtabledata
