import React from 'react'
import Globalheadtabledata from '../../Component/Globalheadtabledata'
import { useNavigate } from 'react-router-dom'

const Techproviderapipage = () => {
    const navigate=useNavigate()
   
    return (
        <>
            <section className='techproviderpagesection'>
                <div className='cust-container'>
                    <Globalheadtabledata text="API Integration Manager" btnname=" Return to Dashboard"  onClick={() => navigate("/techprovider")} />


                    <div className='apiintegrationdatatable'>
                        <div className='apiintegrationdivflx'>
                            <div className='apiintegrationdivleft'>
                                <p className='tsturltextpp'>Test URL :	</p>
                            </div>
                            <div className='apiintegrationdivright'>
                                <p>https://saife-agi.com/techprovider/test </p>
                            </div>
                        </div>
                        <div className='apiintegrationdivflx'>
                            <div className='apiintegrationdivleft'>
                                <p className='tsturltextpp'>Test Key :	</p>
                            </div>
                            <div className='apiintegrationdivright'>
                                <p>ZX12345VBNMCV85WW </p>
                            </div>
                        </div>
                        <div className='apiintegrationdivflx'>
                            <div className='apiintegrationdivleft'>
                                <p className='tsturltextpp'>Username :	</p>
                            </div>
                            <div className='apiintegrationdivright'>
                                <input type='text' className='apiintegrationdiinpy'/>
                            </div>
                        </div>
                        <div className='apiintegrationdivflx'>
                            <div className='apiintegrationdivleft'>
                                <p className='tsturltextpp'> Password :
                                </p>
                            </div>
                            <div className='apiintegrationdivright'>
                                <input type='text' className='apiintegrationdiinpy'/>
                            </div>
                        </div>

                        <button className='cnntnbtnmain'>Connect</button>

                    </div>


                    <div className='apiintegrationdatatable'>
                        <div className='apiintegrationdivflx'>
                            <div className='apiintegrationdivleft'>
                                <p className='tsturltextpp'>Production URL :  </p>
                            </div>
                            <div className='apiintegrationdivright'>
                                <p> https://saife-agi.com/techprovider/production </p>
                            </div>
                        </div>
                        <div className='apiintegrationdivflx'>
                            <div className='apiintegrationdivleft'>
                                <p className='tsturltextpp'>Production Key :  </p>
                            </div>
                            <div className='apiintegrationdivright'>
                                <p>ZX12345VBNMCV85WWPROD</p>
                            </div>
                        </div>
                        <div className='apiintegrationdivflx'>
                            <div className='apiintegrationdivleft'>
                                <p className='tsturltextpp'>Username :	</p>
                            </div>
                            <div className='apiintegrationdivright'>
                                <input type='text' className='apiintegrationdiinpy'/>
                            </div>
                        </div>
                        <div className='apiintegrationdivflx'>
                            <div className='apiintegrationdivleft'>
                                <p className='tsturltextpp'> Password :
                                </p>
                            </div>
                            <div className='apiintegrationdivright'>
                                <input type='text' className='apiintegrationdiinpy'/>
                            </div>
                        </div>

                        <button className='cnntnbtnmain'>Connect</button>

                    </div>

                </div>
            </section>
        </>
    )
}

export default Techproviderapipage
