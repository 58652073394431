import React from 'react'
import Globalparticipantpage from './Globalparticipantpage'
import Globalpoliciespage from '../Globalpolicies/Globalpoliciespage'

const GlobalPartcipant = () => {
  return (
   <>
        <Globalparticipantpage />
   </>
  )
}

export default GlobalPartcipant
