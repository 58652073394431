import React from 'react'
import Techprovidermainpage from './Techprovidermainpage'
import "./Techprovioder.css"
const Techprovider = () => {
  return (
     <>
        <Techprovidermainpage/>
     </>
  )
}

export default Techprovider
