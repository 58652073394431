import React from 'react'
import Globalheadtabledata from '../../Component/Globalheadtabledata'
import { Link, useNavigate } from 'react-router-dom'
const Compliencemanagement = () => {
    const navigate = useNavigate()
    return (
        <>
            <section className='techproviderpagesection'>
                <div className='cust-container'>
                    <div className=''>
                        <div className='giovernencretesypeterchbtnrchdivflx'>
                            <input className='typetoserchbtn' placeholder='Search Policy' />
                            <input className='typetoserchbtn' placeholder='Search Laws' />
                            <button className='runsimulatorbtn'>Run Simulator</button>
                        </div>
                        <Globalheadtabledata text="Digital Court System" btnname=" Return to Dashboard" onClick={() => navigate("/complienceprovider")} />
                    </div>


                    <div className='techproviderriskpolicytabledivmain'>
                        <div className='compliencepolicyandlowmakertablediv'>
                            <div className="compliencetabletbaledivmain">
                                <table className="governencepolicytablediv">
                                    <thead className="offenderdatatableheaddiv">
                                        <tr className="offenderdatatableheadtr">
                                            <td className="governencepolicytableheadtd">Policy Maker
                                            </td>
                                            <td className="governencepolicytableheadtd">Policy Breaches
                                            </td>

                                        </tr>
                                    </thead>
                                    <tbody className="offenderdatatablebodydiv">
                                        <tr className="techproviderrisktableebodytr">
                                            <td className="plicymanagemntdatatbleheadtdd">EUC

                                            </td>
                                            <td className="plicymanagemntdatatbleheadtdd">
                                                <Link to="/Complienceglobalviolationpolicy"> 4,578</Link>


                                            </td>




                                        </tr>

                                        <tr className="techproviderrisktableebodytr">
                                            <td className="plicymanagemntdatatbleheadtdd">NATO



                                            </td>
                                            <td className="plicymanagemntdatatbleheadtdd">


                                                <Link to="/Complienceglobalviolationpolicy">599</Link>
                                            </td>


                                        </tr>

                                        <tr className="techproviderrisktableebodytr">
                                            <td className="plicymanagemntdatatbleheadtdd">UNITED NATIONS



                                            </td>
                                            <td className="plicymanagemntdatatbleheadtdd">

                                                <Link to="/Complienceglobalviolationpolicy"> 453</Link>
                                            </td>




                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                            <div className='borderparticipant'></div>

                            <div className="compliencelowmakerdivtbaledivmain">
                                <table className="governencepolicytablediv">
                                    <thead className="offenderdatatableheaddiv">
                                        <tr className="offenderdatatableheadtr">
                                            <td className="governencepolicytableheadtd">Law Makers

                                            </td>
                                            <td className="governencepolicytableheadtd">Legal Infractions

                                            </td>


                                        </tr>
                                    </thead>
                                    <tbody className="offenderdatatablebodydiv">
                                        <tr className="techproviderrisktableebodytr">
                                            <td className="plicymanagemntdatatbleheadtdd">EUC

                                            </td>
                                            <td className="plicymanagemntdatatbleheadtdd">
                                                <Link to="/complienceglobalpolicylaws"> 4,578 </Link>
                                            </td>




                                        </tr>

                                        <tr className="techproviderrisktableebodytr">
                                            <td className="plicymanagemntdatatbleheadtdd">NATO

                                            </td>
                                            <td className="plicymanagemntdatatbleheadtdd">
                                                <Link to="/complienceglobalpolicylaws"> 599</Link>
                                            </td>




                                        </tr>

                                        <tr className="techproviderrisktableebodytr">
                                            <td className="plicymanagemntdatatbleheadtdd">UNITED NATIONS
                                            </td>
                                            <td className="plicymanagemntdatatbleheadtdd">
                                                <Link to="/complienceglobalpolicylaws">  453 </Link>
                                            </td>

                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                            <div className='borderparticipant'></div>

                            <div className="compliencelowmakerdivtbaledivmain">
                                <table className="governencepolicytablediv">
                                    <thead className="offenderdatatableheaddiv">
                                        <tr className="offenderdatatableheadtr">
                                            <td className="governencepolicytableheadtd">AI Violators


                                            </td>
                                            <td className="governencepolicytableheadtd">Known Risks

                                            </td>


                                        </tr>
                                    </thead>
                                    <tbody className="offenderdatatablebodydiv">
                                        <tr className="techproviderrisktableebodytr">
                                            <td className="plicymanagemntdatatbleheadtdd">IBM
                                            </td>
                                            <td className="plicymanagemntdatatbleheadtdd">
                                                <Link to="/compliencepolicyviolation"> 200</Link>
                                            </td>
                                        </tr>

                                        <tr className="techproviderrisktableebodytr">
                                            <td className="plicymanagemntdatatbleheadtdd">Google

                                            </td>
                                            <td className="plicymanagemntdatatbleheadtdd">
                                            <Link to="/compliencepolicyviolation"> 201</Link>
                                            </td>
                                        </tr>

                                        <tr className="techproviderrisktableebodytr">
                                            <td className="plicymanagemntdatatbleheadtdd">Amazon

                                            </td>
                                            <td className="plicymanagemntdatatbleheadtdd">
                                            <Link to="/compliencepolicyviolation"> 101</Link>
                                            </td>

                                        </tr>

                                        <tr className="techproviderrisktableebodytr">
                                            <td className="plicymanagemntdatatbleheadtdd">People.a

                                            </td>
                                            <td className="plicymanagemntdatatbleheadtdd">
                                            <Link to="/compliencepolicyviolation"> 33</Link>
                                            </td>

                                        </tr>


                                        <tr className="techproviderrisktableebodytr">
                                            <td className="plicymanagemntdatatbleheadtdd">AlphaSense
                                            </td>
                                            <td className="plicymanagemntdatatbleheadtdd">
                                            <Link to="/compliencepolicyviolation"> 78</Link>
                                            </td>

                                        </tr>

                                        <tr className="techproviderrisktableebodytr">
                                            <td className="plicymanagemntdatatbleheadtdd">NVIDIA

                                            </td>
                                            <td className="plicymanagemntdatatbleheadtdd">
                                            <Link to="/compliencepolicyviolation"> 99</Link>
                                            </td>

                                        </tr>

                                        <tr className="techproviderrisktableebodytr">
                                            <td className="plicymanagemntdatatbleheadtdd">DataRobot

                                            </td>
                                            <td className="plicymanagemntdatatbleheadtdd">
                                            <Link to="/compliencepolicyviolation"> 129</Link>
                                            </td>

                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>


                </div>
            </section>
        </>
    )
}

export default Compliencemanagement
