import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Returntypetosearch from '../../Component/Returntypetosearch'

const Riskcategory = () => {

    return (
        <section className='offnedersectionmain'>
            <div className='cust-container'>


                <Returntypetosearch placeholder="Type-to-Search-Risk" returnpage="Return to Dashboard" currentdate="  Current Date : September 4, 2024" to="/offender" />

                <div className=''>
                    <p className='tableheadtxtpp'>Google</p>
                </div>

                <div className='tabledatallforaioffender'>
                    <table className='riskcategorydatatablediv'>
                        <thead className='offenderdatatableheaddiv'>
                            <tr className='offenderdatatableheadtr'>
                                <td className='riskdatatableheadtd '>
                                    Known Risk Category

                                </td>

                                <td className='riskdatatableheadtd '>
                                    Risk Frequency
                                </td>


                                <td className='riskdatatableheadtd '>
                                    User Impact %
                                </td>
                            </tr>
                        </thead>

                        <tbody className='offenderdatatablebodydiv'>
                            <tr className='offenderdatatablebodytr'>
                                <th className='riskdatadatatablebodytd'>
                                    Information Overload

                                </th>
                                <th className='riskdatadatatablebodytd'>

                                    Daily


                                </th>
                                <th className='riskdatadatatablebodytd'>
                                    100
                                </th>
                            </tr>
                            <tr className='offenderdatatablebodytr'>
                                <th className='riskdatadatatablebodytd'>
                                    Addiction


                                </th>
                                <th className='riskdatadatatablebodytd'>

                                    Daily


                                </th>
                                <th className='riskdatadatatablebodytd'>
                                    90
                                </th>
                            </tr>

                            <tr className='offenderdatatablebodytr'>
                                <th className='riskdatadatatablebodytd'>
                                    Doomscrolling
                                </th>
                                <th className='riskdatadatatablebodytd'>

                                    Daily

                                </th>
                                <th className='riskdatadatatablebodytd'>
                                    80
                                </th>
                            </tr>

                            <tr className='offenderdatatablebodytr'>
                                <th className='riskdatadatatablebodytd'>
                                    Inluencer Culture

                                </th>
                                <th className='riskdatadatatablebodytd'>

                                    Daily


                                </th>
                                <th className='riskdatadatatablebodytd'>
                                    77
                                </th>
                            </tr>


                            <tr className='offenderdatatablebodytr'>
                                <th className='riskdatadatatablebodytd'>
                                    Sexualization of Kids

                                </th>
                                <th className='riskdatadatatablebodytd'>

                                    Daily


                                </th>
                                <th className='riskdatadatatablebodytd'>
                                    65
                                </th>
                            </tr>

                            <tr className='offenderdatatablebodytr'>
                                <th className='riskdatadatatablebodytd'>
                                    Qanon
                                </th>
                                <th className='riskdatadatatablebodytd'>
                                    Daily
                                </th>
                                <th className='riskdatadatatablebodytd'>
                                    60
                                </th>
                            </tr>


                            <tr className='offenderdatatablebodytr'>
                                <th className='riskdatadatatablebodytd'>
                                    Shortened Attention

                                </th>
                                <th className='riskdatadatatablebodytd'>

                                    Daily


                                </th>
                                <th className='riskdatadatatablebodytd'>
                                    58
                                </th>
                            </tr>


                            <tr className='offenderdatatablebodytr'>
                                <th className='riskdatadatatablebodytd'>
                                    Spans


                                </th>
                                <th className='riskdatadatatablebodytd'>

                                    Daily


                                </th>
                                <th className='riskdatadatatablebodytd'>
                                    55
                                </th>
                            </tr>


                            <tr className='offenderdatatablebodytr'>
                                <th className='riskdatadatatablebodytd'>
                                    Information Overload

                                </th>
                                <th className='riskdatadatatablebodytd'>

                                    Daily


                                </th>
                                <th className='riskdatadatatablebodytd'>
                                    100
                                </th>
                            </tr>

                            <tr className='offenderdatatablebodytr'>
                                <th className='riskdatadatatablebodytd'>
                                    Polarization

                                </th>
                                <th className='riskdatadatatablebodytd'>
                                    Daily

                                </th>
                                <th className='riskdatadatatablebodytd'>
                                    43
                                </th>
                            </tr>


                            <tr className='offenderdatatablebodytr'>
                                <th className='riskdatadatatablebodytd'>
                                    Bots, DeepFakes

                                </th>
                                <th className='riskdatadatatablebodytd'>
                                    Daily
                                </th>
                                <th className='riskdatadatatablebodytd'>
                                    20
                                </th>
                            </tr>

                            <tr className='offenderdatatablebodytr'>
                                <th className='riskdatadatatablebodytd'>
                                    Cult factories

                                </th>
                                <th className='riskdatadatatablebodytd'>
                                    Daily

                                </th>
                                <th className='riskdatadatatablebodytd'>
                                    12
                                </th>
                            </tr>


                            <tr className='offenderdatatablebodytr'>
                                <th className='riskdatadatatablebodytd'>
                                    Fake News
                                </th>
                                <th className='riskdatadatatablebodytd'>
                                    In-Progress
                                </th>
                                <th className='riskdatadatatablebodytd'>
                                    8
                                </th>
                            </tr>

                            <tr className='offenderdatatablebodytr'>
                                <th className='riskdatadatatablebodytd'>
                                    Breakdown of Democracy
                                </th>
                                <th className='riskdatadatatablebodytd'>
                                    Daily
                                </th>
                                <th className='riskdatadatatablebodytd'>
                                    7
                                </th>
                            </tr>


                            <tr className='offenderdatatablebodytr'>
                                <th className='riskdatadatatablebodytd'>
                                    Mental Health
                                </th>
                                <th className='riskdatadatatablebodytd'>
                                    Daily
                                </th>
                                <th className='riskdatadatatablebodytd'>
                                    5
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </section>
    )
}

export default Riskcategory
