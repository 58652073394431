import React, { useState } from 'react'
import "./Landingpage.css";
import globaldefense from "../../Images/globaldefense.png"
import { useNavigate } from 'react-router-dom';
const Landingpage = () => {

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    
    
    if (username === 'demo' && password === 'demo') {
      
      navigate('/home');
    } else {
      
      setError('Invalid username or password');
    }
  };
  return (
    <section className='landingpagemain'>
      <div className='cust-container '>
        <div className='userformmajhafgloadduvflx'>
          <div className='userformmdivright'>
            <div className=''>
              <form  onSubmit={handleSubmit}>
                <div className='usenammeiinndivghdsjk'>
                  <p className='uurstglkanbdiuixpp'>Usename</p>
                  <div className='inpiuuhsttlamnmdibttxt'>
                    {/* <input type='text' className='inpiuuhsttlamnmdibttxtinpt' /> */}

                    <input
                      type="text"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      placeholder="Enter username"
                      className='inpiuuhsttlamnmdibttxtinpt'
                    />
                  </div>
                </div>

                <div className='usenammeiinndivghdsjk'>
                  <p className='uurstglkanbdiuixpp'>Password</p>
                  <div className='inpiuuhsttlamnmdibttxt'>
                    {/* <input type='password' className='inpiuuhsttlamnmdibttxtinpt' /> */}
                    <input
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Enter password"
                        className='inpiuuhsttlamnmdibttxtinpt'
                    />
                  </div>
                </div>
                {error && <p style={{ color: 'red' }}>{error}</p>}
                <div className='Submitbythhhsmammmain'>
                  <button className='Submitbythhhsmamm' type='submit'>Submit</button>
                </div>
              </form>
            </div>
          </div>

          <div className='userformmdivleftt'>
            <div className='userformmdivglobaldefenseabstlt'>
              <div className='userformmdivglobaldefense'>
                <img src={globaldefense} alt='...' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Landingpage
