import React from 'react'
import Returntypetosearch from '../../Component/Returntypetosearch'
const Globalparticipantpolicylaws = () => {
  return (
    <>
        <section className='offnedersectionmain'>
                <div className='cust-container'>
                    

                    <Returntypetosearch placeholder="Type-to-Search-Law" returnpage="Return to Dashboard" currentdate="  Current Date : September 4, 2024" to="/globalPartcipant" />

                    <div className=''>
                        <p className='tableheadtxtpp'>EUC</p>
                    </div>
                    <div className='tabledatallforaioffender'>
                        <table className='lawinfractiondatatablediv'>

                            <thead className='offenderdatatableheaddiv'>
                                <tr className='offenderdatatableheadtr'>
                                    <td className='lawinfractiondatatableheadtd '>
                                        Law Infractions


                                    </td>

                                    <td className='lawinfractiondatatableheadtd '>
                                        Violation Frequency

                                    </td>


                                    <td className='lawinfractiondatatableheadtd '>
                                        User Impact %
                                    </td>


                                    <td className='lawinfractiondatatableheadtd '>
                                        Law Violator

                                    </td>

                                    <td className='lawinfractiondatatableheadtd '>
                                        Penalty Issued

                                    </td>
                                </tr>
                            </thead>

                            <tbody className='offenderdatatablebodydiv'>
                                <tr className='offenderdatatablebodytr'>
                                    <th className='lawinfractiondatadatatablebodytd'>
                                        Deep Fakes


                                    </th>

                                    <th className='lawinfractiondatadatatablebodytd'>
                                        Daily
                                    </th>

                                    <th className='lawinfractiondatadatatablebodytd'>
                                        100
                                    </th>

                                    <th className='lawinfractiondatadatatablebodytd'>
                                        Google, MSFT, OpenAI

                                    </th>

                                    <th className='lawinfractiondatadatatablebodytd'>
                                        $ FINE

                                    </th>

                                </tr>

                                <tr className='offenderdatatablebodytr'>
                                    <th className='lawinfractiondatadatatablebodytd'>
                                        Teen Suicide



                                    </th>

                                    <th className='lawinfractiondatadatatablebodytd'>
                                        Daily
                                    </th>

                                    <th className='lawinfractiondatadatatablebodytd'>
                                        100
                                    </th>

                                    <th className='lawinfractiondatadatatablebodytd'>
                                        Google, MSFT, OpenAI

                                    </th>

                                    <th className='lawinfractiondatadatatablebodytd'>
                                        $ FINE

                                    </th>

                                </tr>

                                <tr className='offenderdatatablebodytr'>
                                    <th className='lawinfractiondatadatatablebodytd'>
                                        Fake News



                                    </th>

                                    <th className='lawinfractiondatadatatablebodytd'>
                                        Daily
                                    </th>

                                    <th className='lawinfractiondatadatatablebodytd'>
                                        100
                                    </th>

                                    <th className='lawinfractiondatadatatablebodytd'>
                                        Google, MSFT, OpenAI


                                    </th>

                                    <th className='lawinfractiondatadatatablebodytd'>
                                        DESIST WARNING


                                    </th>

                                </tr>

                                <tr className='offenderdatatablebodytr'>
                                    <th className='lawinfractiondatadatatablebodytd'>
                                        Sexualization

                                    </th>

                                    <th className='lawinfractiondatadatatablebodytd'>
                                        Daily
                                    </th>

                                    <th className='lawinfractiondatadatatablebodytd'>
                                        100
                                    </th>

                                    <th className='lawinfractiondatadatatablebodytd'>
                                        Google, MSFT, OpenAI

                                    </th>

                                    <th className='lawinfractiondatadatatablebodytd'>
                                        $ FINE

                                    </th>

                                </tr>


                                <tr className='offenderdatatablebodytr'>
                                    <th className='lawinfractiondatadatatablebodytd'>
                                        Prompt Generated Speech (luring)



                                    </th>

                                    <th className='lawinfractiondatadatatablebodytd'>
                                        Daily
                                    </th>

                                    <th className='lawinfractiondatadatatablebodytd'>
                                        100
                                    </th>

                                    <th className='lawinfractiondatadatatablebodytd'>
                                        Google, MSFT, OpenAI


                                    </th>

                                    <th className='lawinfractiondatadatatablebodytd'>
                                        $ FINE


                                    </th>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
    </>
  )
}

export default Globalparticipantpolicylaws
