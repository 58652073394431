import React from 'react'
import { Link } from 'react-router-dom'

const Returntypetosearch = ({returnpage,placeholder,currentdate,to}) => {
    return (
        <>
        <div className='fisrtallbtndivspcl'>
            <div className='fisrttwobtndiv'>
                <Link className='dashboardbtn' to={to}>
                
                    {returnpage}
                </Link>
                <div className='typeristkbtndiv'>
                    <input className="typeristkbtn"  placeholder={placeholder}/>
                </div>
            </div>
            <div className=''>
                <p className='datacurrenttxtp'>
                  
                    {currentdate}
                </p>
            </div>
        </div>
        </>
    )
}

export default Returntypetosearch
