import React from 'react'

import Governenceprovidermainpage from './Governenceprovidermainpage'

const Governenceprovider = () => {
  return (
     <>
        <Governenceprovidermainpage/>
     </>
  )
}

export default Governenceprovider
