import React from 'react'
import Policyprovidermainpage from './Policyprovidermainpage'
import "./Policyprovider.css"
const Policyprovider = () => {
  return (
    <>

    <Policyprovidermainpage/>
    
 </>
  )
}

export default Policyprovider
