import React from 'react'
import "./offender.css"
import Offenderpage from './Offenderpage'
const Offender = () => {
    return (
        <>
            <Offenderpage />


        </>
    )
}

export default Offender
