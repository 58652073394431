import React from 'react'
import Globalheadtabledata from '../../Component/Globalheadtabledata'
import { useNavigate } from 'react-router-dom'

const Techdigitalrecomendation = () => {
    const navigate=useNavigate()
    return (
        <>
            <section className='techproviderpagesection'>
                <div className='cust-container'>
                    <div className=''>
                    {/* <input className='typetoserchbtn' placeholder='Type-to-search' /> */}
                        <Globalheadtabledata text="Recommendations" btnname=" Return to Dashboard" onClick={()=>navigate("/techprovider")} />
                    </div>


                    <div className='techproviderriskpolicytabledivmain'>
                        <table className="techproviderriskpolicytablediv">
                            <thead className="offenderdatatableheaddiv">
                                <tr className="offenderdatatableheadtr">
                                    <td className="techdigitalrecomendationtableheadtd">Recommendation Category


                                    </td>
                                    <td className="techdigitalrecomendationtableheadtd">Action


                                    </td>

                                </tr>
                            </thead>
                            <tbody className="offenderdatatablebodydiv">
                                <tr className="techproviderrisktableebodytr">
                                    <td className="techdigitalrecomendationdatatablebodytd">Still Learning….

                                    </td>
                                    <td className="techdigitalrecomendationdatatablebodytd">
                                    Still Learning….

                                    </td>
                                </tr>
                               
                                
                              
                            </tbody>
                        </table>

                    </div>
                  
                </div>
            </section>
        </>
    )
}

export default Techdigitalrecomendation
