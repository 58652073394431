import React from 'react'
import { useNavigate } from 'react-router-dom'

const Offenderpage = () => {
    const navigate = useNavigate()
    const knowriskclick = () => {
        navigate("/riskcategory")
    }
    const knowpolicyclcik=()=>{
        navigate("/policyviolation")
    }

    const knowlawinclcik=()=>{
        navigate("/lawinfraction")
    }
    const aioffendersdata = [
        {
            aiproviders: "Google",
            KnownRisks: "1,122",
            PolicyViolations: "122",
            LawInfractions: "22",
            ComplianceRating: "6.9",
            
        },
        {
            aiproviders: "Microsoft",
            KnownRisks: "1,007",
            PolicyViolations: "402",
            LawInfractions: "17",
            ComplianceRating: "7.3"
        },
        {
            aiproviders: "OpenAI",
            KnownRisks: "1,003",
            PolicyViolations: "429",
            LawInfractions: "19",
            ComplianceRating: "7.9"
        },
    ]
    return (
        <>
            <section className='offnedersectionmain'>
                <div className='cust-container'>
                    <div className='currentandtypesrchntndiv'>
                        <p className='datacurrenttxtp'>
                            Current Date : September 4, 2024
                        </p>
                        <div className='typesrchbtndiv'>
                            <input type='text' placeholder=' Type-to-Search-Offenders' className='typesrchbtn' />
                        </div>
                    </div>

                    <div className=''>
                        <p className='tableheadtxtpp'>AI Offenders</p>
                    </div>

                    <div className='tabledatallforaioffender'>
                        <table className='offenderdatatablediv'>
                            <thead className='offenderdatatableheaddiv'>
                                <tr className='offenderdatatableheadtr'>
                                    <td className='offenderdatatableheadtd'>
                                        AI Providers
                                    </td>

                                    <td className='offenderdatatableheadtd'>
                                        Known Risks

                                    </td>


                                    <td className='offenderdatatableheadtd'>
                                        Policy Violations

                                    </td>


                                    <td className='offenderdatatableheadtd'>
                                        Law Infractions

                                    </td>

                                    <td className='offenderdatatableheadtd'>
                                        AI Compliance Rating


                                    </td>
                                </tr>
                            </thead>
                            <tbody className='offenderdatatablebodydiv'>
                                {aioffendersdata.map((item, index) => {
                                    return (
                                        <tr className='offenderdatatablebodytr' key={index}>
                                            <td className='offenderdatatablebodytd'>
                                                {item.aiproviders}
                                            </td>

                                            <td className='offenderdatatablebodytd' onClick={knowriskclick}>
                                                {item.KnownRisks}
                                                <span className='arrowupoffenderdiv'>
                                                    <i className="fa-solid fa-caret-up"></i>
                                                </span>
                                            </td>


                                            <td className='offenderdatatablebodytd' onClick={knowpolicyclcik}>
                                                {item.PolicyViolations}
                                                <span className='arrowupoffenderdiv'>
                                                    <i className="fa-solid fa-caret-up"></i>
                                                </span>
                                            </td>


                                            <td className='offenderdatatablebodytd' onClick={knowlawinclcik}>
                                                {item.LawInfractions}
                                                <span className='arrowupoffenderdiv'>
                                                    <i className="fa-solid fa-caret-up"></i>
                                                </span>
                                            </td>

                                            <td className='offenderdatatablebodytd'>
                                                {item.ComplianceRating}

                                            </td>
                                        </tr>
                                    )
                                })}
                               
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Offenderpage
