import React from 'react'
import Globalheadtabledata from '../../Component/Globalheadtabledata'
import { useNavigate } from 'react-router-dom'
const Governencecomplienceoffenders = () => {
    const navigate=useNavigate()
  return (
  <>
           <section className='techproviderpagesection'>
                <div className='cust-container'>
                    <div className=''>
                        <div className='typetosetypeterchbtnrchdivflx'>
                            <button className='typetoserchbtn'>Type-to-search</button>
                            <button className='runsimulatorbtn'>Run Simulator</button>
                        </div>
                        <Globalheadtabledata text="AI Compliance Offenders" btnname=" Return to Dashboard"  onClick={() => navigate("/governenceprovider")}/>
                    </div>

                    <div className='techproviderriskpolicytabledivmain'>
                        <table className="techproviderriskpolicytablediv">
                            <thead className="offenderdatatableheaddiv">
                                <tr className="offenderdatatableheadtr">
                                    <td className="techproviderrisktableheadtd">AI Violator Name

                                    </td>
                                    <td className="techproviderrisktableheadtd">Policy Violations
                                    </td>

                                </tr>
                            </thead>
                            <tbody className="offenderdatatablebodydiv">
                                <tr className="techproviderrisktableebodytr">
                                    <td className="techproviderdatatablebodytd">IBM

                                    </td>
                                    <td className="techproviderdatatablebodytd">
                                        200
                                    </td>
                                </tr>

                                <tr className="techproviderrisktableebodytr">
                                    <td className="techproviderdatatablebodytd">Google

                                    </td>
                                    <td className="techproviderdatatablebodytd">
                                        101
                                    </td>
                                </tr>

                                <tr className="techproviderrisktableebodytr">
                                    <td className="techproviderdatatablebodytd">Amazon


                                    </td>
                                    <td className="techproviderdatatablebodytd">
                                        406

                                    </td>
                                </tr>


                                <tr className="techproviderrisktableebodytr">
                                    <td className="techproviderdatatablebodytd">AlphaSense


                                    </td>
                                    <td className="techproviderdatatablebodytd">
                                        33

                                    </td>
                                </tr>


                                <tr className="techproviderrisktableebodytr">
                                    <td className="techproviderdatatablebodytd">NVIDIA


                                    </td>
                                    <td className="techproviderdatatablebodytd">
                                        78

                                    </td>
                                </tr>


                                <tr className="techproviderrisktableebodytr">
                                    <td className="techproviderdatatablebodytd">DataRobot


                                    </td>
                                    <td className="techproviderdatatablebodytd">
                                        99

                                    </td>
                                </tr>


                                <tr className="techproviderrisktableebodytr">
                                    <td className="techproviderdatatablebodytd">Qanon

                                    </td>
                                    <td className="techproviderdatatablebodytd">
                                        117

                                    </td>
                                </tr>


                                <tr className="techproviderrisktableebodytr">
                                    <td className="techproviderdatatablebodytd">H2O.ai


                                    </td>
                                    <td className="techproviderdatatablebodytd">
                                        107


                                    </td>
                                </tr>

                                <tr className="techproviderrisktableebodytr">
                                    <td className="techproviderdatatablebodytd">Polarization

                                    </td>
                                    <td className="techproviderdatatablebodytd">
                                        38

                                    </td>
                                </tr>
                                <tr className="techproviderrisktableebodytr">
                                    <td className="techproviderdatatablebodytd">OpenAI

                                    </td>
                                    <td className="techproviderdatatablebodytd">
                                        39

                                    </td>
                                </tr>
                                <tr className="techproviderrisktableebodytr">
                                    <td className="techproviderdatatablebodytd">Clarifai


                                    </td>
                                    <td className="techproviderdatatablebodytd">
                                        54

                                    </td>
                                </tr>
                                <tr className="techproviderrisktableebodytr">
                                    <td className="techproviderdatatablebodytd">Acrisure Technology Group


                                    </td>
                                    <td className="techproviderdatatablebodytd">
                                        32

                                    </td>
                                </tr>
                                <tr className="techproviderrisktableebodytr">
                                    <td className="techproviderdatatablebodytd">STR


                                    </td>
                                    <td className="techproviderdatatablebodytd">
                                        10
                                    </td>
                                </tr>

                            </tbody>
                        </table>

                    </div>
                </div>
            </section>
  </>
  )
}

export default Governencecomplienceoffenders
