import React from 'react'
import { Link } from 'react-router-dom'
import Returntypetosearch from '../../Component/Returntypetosearch'
const Globalparticipantrisk = () => {
  return (
   <>
              <section className='offnedersectionmain'>
                <div className='cust-container'>


                    <Returntypetosearch placeholder="Type-to-Search-Risk" returnpage="Return to Dashboard" currentdate="  Current Date : September 4, 2024" to="/globalPartcipant" />

                    <div className=''>
                        <p className='tableheadtxtpp'>EUC</p>
                    </div>

                    <div className='tabledatallforaioffender'>
                        <table className='globalriodkdatatablediv'>
                            <thead className='offenderdatatableheaddiv'>
                                <tr className='offenderdatatableheadtr'>
                                    <td className='globalriskdatatableheadtd '>
                                        Known Risk Category

                                    </td>

                                    <td className='globalriskdatatableheadtd '>
                                        Risk Frequency
                                    </td>


                                    <td className='globalriskdatatableheadtd '>
                                        User Impact %
                                    </td>

                                    <td className='globalriskdatatableheadtd '>
                                        AI Risk Violators

                                    </td>
                                </tr>
                            </thead>

                            <tbody className='offenderdatatablebodydiv'>
                                <tr className='offenderdatatablebodytr'>
                                    <th className='globalriskdatadatatablebodytd'>
                                        Information Overload

                                    </th>
                                    <th className='globalriskdatadatatablebodytd'>

                                        Daily


                                    </th>
                                    <th className='globalriskdatadatatablebodytd'>
                                        100
                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        Google, MSFT, OpenAI

                                    </th>
                                </tr>
                                <tr className='offenderdatatablebodytr'>
                                    <th className='globalriskdatadatatablebodytd'>
                                        Addiction


                                    </th>
                                    <th className='globalriskdatadatatablebodytd'>

                                        Daily


                                    </th>
                                    <th className='globalriskdatadatatablebodytd'>
                                        90
                                    </th>
                                    <th className='globalriskdatadatatablebodytd'>
                                        Google, MSFT, OpenAI

                                    </th>
                                </tr>

                                <tr className='offenderdatatablebodytr'>
                                    <th className='globalriskdatadatatablebodytd'>
                                        Doomscrolling
                                    </th>
                                    <th className='globalriskdatadatatablebodytd'>

                                        Daily

                                    </th>
                                    <th className='globalriskdatadatatablebodytd'>
                                        80
                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        Google, MSFT, OpenAI

                                    </th>
                                </tr>

                                <tr className='offenderdatatablebodytr'>
                                    <th className='globalriskdatadatatablebodytd'>
                                        Inluencer Culture

                                    </th>
                                    <th className='globalriskdatadatatablebodytd'>

                                        Daily


                                    </th>
                                    <th className='globalriskdatadatatablebodytd'>
                                        77
                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        Google, MSFT, OpenAI

                                    </th>
                                </tr>


                                <tr className='offenderdatatablebodytr'>
                                    <th className='globalriskdatadatatablebodytd'>
                                        Sexualization of Kids

                                    </th>
                                    <th className='globalriskdatadatatablebodytd'>

                                        Daily


                                    </th>
                                    <th className='globalriskdatadatatablebodytd'>
                                        65
                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        Google, MSFT, OpenAI

                                    </th>
                                </tr>

                                <tr className='offenderdatatablebodytr'>
                                    <th className='globalriskdatadatatablebodytd'>
                                        Qanon
                                    </th>
                                    <th className='globalriskdatadatatablebodytd'>
                                        Daily
                                    </th>
                                    <th className='globalriskdatadatatablebodytd'>
                                        60
                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        Google, MSFT, OpenAI

                                    </th>
                                </tr>


                                <tr className='offenderdatatablebodytr'>
                                    <th className='globalriskdatadatatablebodytd'>
                                        Shortened Attention

                                    </th>
                                    <th className='globalriskdatadatatablebodytd'>

                                        Daily


                                    </th>
                                    <th className='globalriskdatadatatablebodytd'>
                                        58
                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        Google, MSFT, OpenAI

                                    </th>
                                </tr>


                                <tr className='offenderdatatablebodytr'>
                                    <th className='globalriskdatadatatablebodytd'>
                                        Spans


                                    </th>
                                    <th className='globalriskdatadatatablebodytd'>

                                        Daily


                                    </th>
                                    <th className='globalriskdatadatatablebodytd'>
                                        55
                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        Google, MSFT, OpenAI

                                    </th>
                                </tr>


                                <tr className='offenderdatatablebodytr'>
                                    <th className='globalriskdatadatatablebodytd'>
                                        Information Overload

                                    </th>
                                    <th className='globalriskdatadatatablebodytd'>

                                        Daily


                                    </th>
                                    <th className='globalriskdatadatatablebodytd'>
                                        100
                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        Google, MSFT, OpenAI

                                    </th>
                                </tr>

                                <tr className='offenderdatatablebodytr'>
                                    <th className='globalriskdatadatatablebodytd'>
                                        Polarization

                                    </th>
                                    <th className='globalriskdatadatatablebodytd'>
                                        Daily

                                    </th>
                                    <th className='globalriskdatadatatablebodytd'>
                                        43
                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        Google, MSFT, OpenAI

                                    </th>
                                </tr>


                                <tr className='offenderdatatablebodytr'>
                                    <th className='globalriskdatadatatablebodytd'>
                                        Bots, DeepFakes

                                    </th>
                                    <th className='globalriskdatadatatablebodytd'>
                                        Daily
                                    </th>
                                    <th className='globalriskdatadatatablebodytd'>
                                        20
                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        Google, MSFT, OpenAI

                                    </th>
                                </tr>

                                <tr className='offenderdatatablebodytr'>
                                    <th className='globalriskdatadatatablebodytd'>
                                        Cult factories

                                    </th>
                                    <th className='globalriskdatadatatablebodytd'>
                                        Daily

                                    </th>
                                    <th className='globalriskdatadatatablebodytd'>
                                        12
                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        Google, MSFT, OpenAI

                                    </th>
                                </tr>


                                <tr className='offenderdatatablebodytr'>
                                    <th className='globalriskdatadatatablebodytd'>
                                        Fake News
                                    </th>
                                    <th className='globalriskdatadatatablebodytd'>
                                        In-Progress
                                    </th>
                                    <th className='globalriskdatadatatablebodytd'>
                                        8
                                    </th>
                                    <th className='globalriskdatadatatablebodytd'>
                                        Google, MSFT, OpenAI

                                    </th>
                                </tr>

                                <tr className='offenderdatatablebodytr'>
                                    <th className='globalriskdatadatatablebodytd'>
                                        Breakdown of Democracy
                                    </th>
                                    <th className='globalriskdatadatatablebodytd'>
                                        Daily
                                    </th>
                                    <th className='globalriskdatadatatablebodytd'>
                                        7
                                    </th>
                                    <th className='globalriskdatadatatablebodytd'>
                                        Google, MSFT, OpenAI

                                    </th>
                                </tr>


                                <tr className='offenderdatatablebodytr'>
                                    <th className='globalriskdatadatatablebodytd'>
                                        Mental Health
                                    </th>
                                    <th className='globalriskdatadatatablebodytd'>
                                        Daily
                                    </th>
                                    <th className='globalriskdatadatatablebodytd'>
                                        5
                                    </th>
                                    <th className='globalriskdatadatatablebodytd'>
                                        Google, MSFT, OpenAI

                                    </th>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </section>
   </>
  )
}

export default Globalparticipantrisk
