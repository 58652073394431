import React from 'react'
import Globalheadtabledata from '../../Component/Globalheadtabledata'
import { useNavigate } from 'react-router-dom'
const Complienceriskandpolicy = () => {
    const navigate=useNavigate()
  return (
    <>
               <section className='techproviderpagesection'>
                <div className='cust-container'>
                    <div className=''>
                        <div className='typetosetypeterchbtnrchdivflx'>
                            <button className='typetoserchbtn'>Type-to-search</button>
                            <button className='runsimulatorbtn'>Run Simulator</button>
                        </div>
                        <Globalheadtabledata text="Risk & Policy Breaches" btnname=" Return to Dashboard" onClick={() => navigate("/complienceprovider")} />
                    </div>

                    <div className='techproviderriskpolicytabledivmain'>
                        <table className="policyproviderpolicytablediv">
                            <thead className="offenderdatatableheaddiv">
                                <tr className="offenderdatatableheadtr">
                                    <td className="policyproviderisktableheadtd">Risk Category


                                    </td>
                                    <td className="policyproviderisktableheadtd">Policy Violations

                                    </td>

                                    <td className="policyproviderisktableheadtd">AI Violators


                                    </td>

                                </tr>
                            </thead>

                            <tbody className="offenderdatatablebodydiv">
                                <tr className="techproviderrisktableebodytr">
                                    <td className="policyprovideerriskdatatablebodytd">Information Overload
                                    </td>
                                    <td className="policyprovideerriskdatatablebodytd">
                                        12
                                    </td>
                                    <td className="policyprovideerriskdatatablebodytd">
                                        3
                                    </td>
                                </tr>

                                <tr className="techproviderrisktableebodytr">
                                    <td className="policyprovideerriskdatatablebodytd">Information Overload
                                    </td>
                                    <td className="policyprovideerriskdatatablebodytd">
                                        12
                                    </td>
                                    <td className="policyprovideerriskdatatablebodytd">
                                        4
                                    </td>
                                </tr>

                                <tr className="techproviderrisktableebodytr">
                                    <td className="policyprovideerriskdatatablebodytd">Addiction

                                    </td>
                                    <td className="policyprovideerriskdatatablebodytd">
                                        0

                                    </td>

                                    <td className="policyprovideerriskdatatablebodytd">
                                        3
                                    </td>
                                </tr>


                                <tr className="techproviderrisktableebodytr">
                                    <td className="policyprovideerriskdatatablebodytd">Doomscrolling

                                    </td>
                                    <td className="policyprovideerriskdatatablebodytd">
                                        7

                                    </td>

                                    <td className="policyprovideerriskdatatablebodytd">
                                        5
                                    </td>
                                </tr>


                                <tr className="techproviderrisktableebodytr">
                                    <td className="policyprovideerriskdatatablebodytd">Inluencer Culture

                                    </td>
                                    <td className="policyprovideerriskdatatablebodytd">
                                        0

                                    </td>

                                    <td className="policyprovideerriskdatatablebodytd">
                                        7
                                    </td>
                                </tr>


                                <tr className="techproviderrisktableebodytr">
                                    <td className="policyprovideerriskdatatablebodytd">Sexualization of Kids

                                    </td>
                                    <td className="policyprovideerriskdatatablebodytd">
                                        300,000

                                    </td>
                                    <td className="policyprovideerriskdatatablebodytd">
                                        8
                                    </td>
                                </tr>


                                <tr className="techproviderrisktableebodytr">
                                    <td className="policyprovideerriskdatatablebodytd">Qanon

                                    </td>
                                    <td className="policyprovideerriskdatatablebodytd">
                                        117

                                    </td>
                                    <td className="policyprovideerriskdatatablebodytd">
                                        3
                                    </td>
                                </tr>


                                <tr className="techproviderrisktableebodytr">
                                    <td className="policyprovideerriskdatatablebodytd">Shortened Attention Spans

                                    </td>
                                    <td className="policyprovideerriskdatatablebodytd">
                                        1,345,000

                                    </td>
                                    <td className="policyprovideerriskdatatablebodytd">
                                        4
                                    </td>
                                </tr>

                                <tr className="techproviderrisktableebodytr">
                                    <td className="policyprovideerriskdatatablebodytd">Polarization

                                    </td>
                                    <td className="policyprovideerriskdatatablebodytd">
                                        38

                                    </td>

                                    <td className="policyprovideerriskdatatablebodytd">
                                        3
                                    </td>
                                </tr>
                                <tr className="techproviderrisktableebodytr">
                                    <td className="policyprovideerriskdatatablebodytd">Bots, DeepFakes
                                    </td>
                                    <td className="policyprovideerriskdatatablebodytd">
                                        400,223

                                    </td>

                                    <td className="policyprovideerriskdatatablebodytd">
                                        7
                                    </td>
                                </tr>
                                <tr className="techproviderrisktableebodytr">
                                    <td className="policyprovideerriskdatatablebodytd">Cult factories

                                    </td>
                                    <td className="policyprovideerriskdatatablebodytd">
                                        3

                                    </td>
                                    <td className="policyprovideerriskdatatablebodytd">
                                        9
                                    </td>
                                </tr>
                                <tr className="techproviderrisktableebodytr">
                                    <td className="policyprovideerriskdatatablebodytd">Fake News

                                    </td>
                                    <td className="policyprovideerriskdatatablebodytd">
                                        50,000,000

                                    </td>
                                    <td className="policyprovideerriskdatatablebodytd">
                                        12
                                    </td>
                                </tr>
                                <tr className="techproviderrisktableebodytr">
                                    <td className="policyprovideerriskdatatablebodytd">Breakdown of Democracy

                                    </td>
                                    <td className="policyprovideerriskdatatablebodytd">
                                        3
                                    </td>
                                    <td className="policyprovideerriskdatatablebodytd">
                                        10
                                    </td>
                                </tr>

                            </tbody>
                        </table>

                    </div>
                </div>
            </section>
    </>
  )
}

export default Complienceriskandpolicy
