import React, { useState } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import logo from "../../src/Images/logo.png"
const Header = () => {
    const location = useLocation();
    const [sidebaropen, setsidebaropen] = useState(false)
    const handleopen = () => {
        setsidebaropen(true)
    }
    const handleclose = () => {
        setsidebaropen(false)
    }
    return (
        <>
            <section className='headermain'>
                <div className='cust-container'>
                    <div className='logo-menudivmain'>
                        <Link className='logolnk' to="/home">
                            <figure className='logolnkimage'>
                                <img src={logo} alt='logo' />
                            </figure>
                        </Link>
                        <div className='alllinddivflx'>
                            <ul className='alllinddivflxul'>
                                <li className='alllinddivflxli'>
                                    <NavLink className='alllinddivlitxtpp' to="/home">
                                        Home
                                    </NavLink>
                                </li>
                                <li className='alllinddivflxli'>


                                    <NavLink
                                        className={({ isActive }) =>
                                            (isActive || location.pathname === '/riskcategory' || location.pathname === '/policyviolation' || location.pathname === '/lawinfraction')
                                                ? 'alllinddivlitxtpp active-link'
                                                : 'alllinddivlitxtpp'
                                        }
                                        to="/offender"
                                    >
                                        Offenders
                                    </NavLink>

                                </li>
                                <li className='alllinddivflxli'>

                                    <NavLink
                                        className={({ isActive }) =>
                                            (isActive || location.pathname === '/globalrisk' || location.pathname === '/globalviolationpolicy' || location.pathname === '/globalpolicylaws')
                                                ? 'alllinddivlitxtpp active-link'
                                                : 'alllinddivlitxtpp'
                                        }
                                        to="/globalpolicies"
                                    >
                                        Global Policies
                                    </NavLink>


                                </li>
                                <li className='alllinddivflxli'>
                                    {/* <NavLink className='alllinddivlitxtpp' to="/globalPartcipant">
                                        Global Participants
                                    </NavLink> */}

                                    <NavLink
                                        className={({ isActive }) =>
                                            (isActive || location.pathname === '/globalparticipantrisk' || location.pathname === '/globalparticipantviolationpolicy' || location.pathname === '/globalparticipantpolicylaws')
                                                ? 'alllinddivlitxtpp active-link'
                                                : 'alllinddivlitxtpp'
                                        }
                                        to="/globalPartcipant"
                                    >
                                         Global Participants
                                    </NavLink>
                                </li>
                                <li className='alllinddivflxli'>
                                    <NavLink className='alllinddivlitxtpp' to="/aboutus">
                                        About Us
                                    </NavLink>
                                </li>
                            </ul>

                            <ul className='allnewlinkdivul'>
                                <li className='allnewlinkdivli'>
                                    <select className="translatecustom">
                                        <option value="spanish">EN</option>
                                        <option value="eng">ES</option>
                                    </select>
                                    <span class="borderrightcustom"></span>
                                </li>
                                <li className='allnewlinkdivli'>
                                    <Link className='lgnbtn' to="/login">Log In</Link>
                                </li>

                                <li className='headerspcbtnli'>
                                    <Link className='sgnbtn' to="/registration">Sign Up</Link>
                                </li>

                                <li className='headerspcbtnli'>
                                    <Link className='offwnderbtn' to="/aihuminty"> Offender Registry
                                    </Link>
                                </li>

                            </ul>
                        </div>

                        <div className='resbar' onClick={handleopen}>
                            <i className="fa-solid fa-bars"></i>
                        </div>
                    </div>
                </div>
            </section>

            {/* sidebar */}
            <section className={sidebaropen ? 'sidebarmain actv' : "sidebarmain"}>
                <div className='sidebarmaincontent'>
                    <div className='sidebarlogomain'>
                        <img src={logo} alt='logo' />
                    </div>
                    <div className='circlecressdiv' onClick={handleclose}>
                        <i class="fa-regular fa-circle-xmark"></i>
                    </div>
                </div>

                <div className='sidebarmenudiv'>
                    <ul className='sidebarmenudivul'>
                        <li className='sidebarmenudivli' >
                            <NavLink className='sidebarmenudivlitxtpp' to="/home" onClick={handleclose}>
                                Home
                            </NavLink>
                        </li>

                        <li className='sidebarmenudivli' >


                            <NavLink onClick={handleclose}
                                className={({ isActive }) =>
                                    (isActive || location.pathname === '/riskcategory' || location.pathname === '/policyviolation' || location.pathname === '/lawinfraction')
                                        ? 'sidebarmenudivlitxtpp active-link'
                                        : 'sidebarmenudivlitxtpp'
                                }
                                to="/offender"
                            >
                                Offenders
                            </NavLink>

                        </li>


                        <li className='sidebarmenudivli'  >


                            <NavLink onClick={handleclose}
                                className={({ isActive }) =>
                                    (isActive || location.pathname === '/riskcategory' || location.pathname === '/policyviolation' || location.pathname === '/lawinfraction')
                                        ? 'sidebarmenudivlitxtpp active-link'
                                        : 'sidebarmenudivlitxtpp'
                                }
                                to="/globalpolicies"
                            >
                                Global Policies
                            </NavLink>

                        </li>


                        <li className='sidebarmenudivli'>
                            <NavLink className='sidebarmenudivlitxtpp' to="/globalPartcipant" onClick={handleclose}>
                                Global Participants
                            </NavLink>
                        </li>

                        <li className='sidebarmenudivli'>
                            <NavLink className='sidebarmenudivlitxtpp' to="/" onClick={handleclose}>
                                About Us
                            </NavLink>
                        </li>

                        <li className='sidebarmenudivli'>
                            <select className="translatecustom">
                                <option value="spanish" onClick={handleclose}>EN</option>
                                <option value="eng" onClick={handleclose}>ES</option>
                            </select>

                        </li>

                        <li className='sidebarmenudivli' >
                            <Link className='lgnbtn' to="/login" onClick={handleclose}>Log In</Link>
                        </li>

                        <li className='sidebarmenudivli' >
                            <Link className='sgnbtn' to="/registration" onClick={handleclose}>Sign Up</Link>
                        </li>

                        <li className='sidebarmenudivli' >
                            <button className='offwnderbtn' onClick={handleclose}>Offender Registry
                            </button>
                        </li>
                    </ul>


                </div>
            </section>
        </>
    )
}

export default Header
