import React from 'react'
import globalwithouttext from "../../Images/globalwithouttext.png"
import { Link } from 'react-router-dom';
import "./About.css"
const About = () => {
  return (
      <>
            <section className='techprovidermainsection'>
                <div className='cust-container'>
                    <div className=''>
                        <p className='abtusttxtppp'>About Us</p>
                    </div>

                    <div className='aboutmaindiuvdivkkkk'>
                       
                             
                            <div className='aboutquestiondivsss'>
                                <div className='abouttabotatdivp'>
                                    <h4 className='abouttqisyytsttxpp'>Why ?</h4>
                                    <p className='abouttanswerrttsttxpp'>
                                    S.A.I.F.E exists to provide safe & secure AI for all of humanity.
                                    </p>
                                </div>

                                <div className='abouttabotatdivp'>
                                    <h4 className='abouttqisyytsttxpp'>WHAT?
                                    </h4>
                                    <p className='abouttanswerrttsttxpp'>
                                    Global defense system that monitors & critiques AI & AGI systems.

                                    </p>
                                </div>

                                <div className='abouttabotatdivp'>
                                    <h4 className='abouttqisyytsttxpp'>HOW?
                                    </h4>
                                    <p className='abouttanswerrttsttxpp'>
                                    Global participation. Analyzers & Simulators. Digital Court System.

                                    </p>
                                </div>

                            </div>
                            <div className='globaldefenseiaboytmgmainfortch'>
                                <div className='globaldefenseimgmainglbl'>
                                    <img src={globalwithouttext} alt='...' />
                                </div>
                            </div>
                        
                    </div>

                  


                </div>
            </section>
      </>
  )
}

export default About
