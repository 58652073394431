import React from 'react'
import { Link } from 'react-router-dom'
import Returntypetosearch from '../../Component/Returntypetosearch'
import Globalviolationpolicytable from '../../Component/Globalviolationpolicytable'

const Globalviolationpolicy = () => {
    return (
        <>
            <section className='offnedersectionmain'>
                <div className='cust-container'>
                    <Returntypetosearch  placeholder="Type-to-Search-policy" returnpage="Return to Dashboard"  currentdate="  Current Date : September 4, 2024" to="/globalpolicies"/>

                    <Globalviolationpolicytable />

                </div>
            </section>
        </>
    )
}

export default Globalviolationpolicy
