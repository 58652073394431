import React from 'react'
import Globalpoliciespage from './Globalpoliciespage'
import "./Globalpolices.css"
const Globalpolicies = () => {
  return (
      <>
          <Globalpoliciespage />
      </>
  )
}

export default Globalpolicies
