import React from 'react'
import Complienceprovidermainpage from './Complienceprovidermainpage'
import "./Complienceprovider.css"
const Complienceprovider = () => {
  return (
    <>
    
      <Complienceprovidermainpage/>
    </>
  )
}

export default Complienceprovider
