import React from 'react'

const Globalviolationpolicytable = () => {
  return (
    <>
        
        <div className=''>
                        <p className='tableheadtxtpp'>EUC
                        </p>
                    </div>

                    <div className='tabledatallforaioffender'>
                        <table className='globalriodkdatatablediv'>

                            <thead className='offenderdatatableheaddiv'>
                                <tr className='offenderdatatableheadtr'>
                                    <td className='globalriskdatatableheadtd '>
                                        Policy Violation

                                    </td>

                                    <td className='globalriskdatatableheadtd '>
                                        Violation Frequency

                                    </td>


                                    <td className='globalriskdatatableheadtd '>
                                        User Impact %
                                    </td>


                                    <td className='globalriskdatatableheadtd '>
                                        AI Policy Violators

                                    </td>
                                </tr>
                            </thead>

                            <tbody className='offenderdatatablebodydiv'>
                                <tr className='offenderdatatablebodytr'>
                                    <th className='globalriskdatadatatablebodytd'>
                                        Natural Language

                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        Daily
                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        100
                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        Google, MSFT, OpenAI

                                    </th>

                                </tr>

                                <tr className='offenderdatatablebodytr'>
                                    <th className='globalriskdatadatatablebodytd'>
                                        fMRI Translations


                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        Daily
                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        100
                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        Google, MSFT, OpenAI

                                    </th>

                                </tr>

                                <tr className='offenderdatatablebodytr'>
                                    <th className='globalriskdatadatatablebodytd'>
                                        WiFi Radio Signal (Omnipresent Surveillance)


                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        Daily
                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        100
                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>

                                        Google, MSFT, OpenAI

                                    </th>

                                </tr>

                                <tr className='offenderdatatablebodytr'>
                                    <th className='globalriskdatadatatablebodytd'>
                                        Code exploitation

                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        Daily
                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        100
                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        Google, MSFT, OpenAI

                                    </th>

                                </tr>


                                <tr className='offenderdatatablebodytr'>
                                    <th className='globalriskdatadatatablebodytd'>
                                        GDP


                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        Daily
                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        100
                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        Google, MSFT, OpenAI

                                    </th>

                                </tr>

                                <tr className='offenderdatatablebodytr'>
                                    <th className='globalriskdatadatatablebodytd'>
                                        Children’s Identity
                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        Daily
                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        100
                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        Google, MSFT, OpenAI

                                    </th>

                                </tr>

                                <tr className='offenderdatatablebodytr'>
                                    <th className='globalriskdatadatatablebodytd'>
                                        Theory of Mind


                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        Daily
                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        100
                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>

                                        Google, MSFT, OpenAI

                                    </th>

                                </tr>

                                <tr className='offenderdatatablebodytr'>
                                    <th className='globalriskdatadatatablebodytd'>
                                        Elections
                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        Daily
                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        100
                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>

                                        Google, MSFT, OpenAI

                                    </th>

                                </tr>


                                <tr className='offenderdatatablebodytr'>
                                    <th className='globalriskdatadatatablebodytd'>
                                        Natural Language

                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        Daily
                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        100
                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        Google, MSFT, OpenAI

                                    </th>

                                </tr>

                                <tr className='offenderdatatablebodytr'>
                                    <th className='globalriskdatadatatablebodytd'>
                                        fMRI Translations


                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        Daily
                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        100
                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        Google, MSFT, OpenAI

                                    </th>

                                </tr>

                                <tr className='offenderdatatablebodytr'>
                                    <th className='globalriskdatadatatablebodytd'>
                                        WiFi Radio Signal (Omnipresent Surveillance)


                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        Daily
                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        100
                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>

                                        Google, MSFT, OpenAI

                                    </th>

                                </tr>

                                <tr className='offenderdatatablebodytr'>
                                    <th className='globalriskdatadatatablebodytd'>
                                        Code exploitation

                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        Daily
                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        100
                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        Google, MSFT, OpenAI

                                    </th>

                                </tr>


                                <tr className='offenderdatatablebodytr'>
                                    <th className='globalriskdatadatatablebodytd'>
                                        GDP


                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        Daily
                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        100
                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        Google, MSFT, OpenAI

                                    </th>

                                </tr>

                                <tr className='offenderdatatablebodytr'>
                                    <th className='globalriskdatadatatablebodytd'>
                                        Children’s Identity
                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        Daily
                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        100
                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        Google, MSFT, OpenAI

                                    </th>

                                </tr>

                                <tr className='offenderdatatablebodytr'>
                                    <th className='globalriskdatadatatablebodytd'>
                                        Theory of Mind


                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        Daily
                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        100
                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>

                                        Google, MSFT, OpenAI

                                    </th>

                                </tr>

                                <tr className='offenderdatatablebodytr'>
                                    <th className='globalriskdatadatatablebodytd'>
                                        Elections
                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        Daily
                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>
                                        100
                                    </th>

                                    <th className='globalriskdatadatatablebodytd'>

                                        Google, MSFT, OpenAI

                                    </th>

                                </tr>



                            </tbody>
                        </table>
                    </div>
    
    </>
  )
}

export default Globalviolationpolicytable
