import React from 'react'
import "./Home.css";
import globaldefense from "../../Images/globaldefense.png"
const Home = () => {
    return (
        <>
            <section className='homebanner'>
                <div className='cust-container'>
                    <div className='homepagespcllogo'>
                        <figure className='globaldefenseimg'>
                            <img src={globaldefense} alt='...' />

                        </figure>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Home
