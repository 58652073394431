import React from 'react'
import { Link } from 'react-router-dom'
import Returntypetosearch from '../../Component/Returntypetosearch'

const Lawinfraction = () => {
  return (
      <>
              <section className='offnedersectionmain'>
                <div className='cust-container'>
                    {/* <div className='fisrtallbtndivspcl'>
                        <div className='fisrttwobtndiv'>
                            <Link className='dashboardbtn' to="/offender">
                                Return to Dashboard
                            </Link>
                            <div className='typeristkbtndiv'>
                                <input className="typeristkbtn" placeholder=' Type-to-Search-Law' />
                            </div>
                        </div>
                        <div className=''>
                            <p className='datacurrenttxtp'>
                                Current Date : September 4, 2024
                            </p>
                        </div>
                    </div> */}

<Returntypetosearch  placeholder="Type-to-Search-Law" returnpage="Return to Dashboard"  currentdate="  Current Date : September 4, 2024" to="/offender"/>

                    <div className=''>
                        <p className='tableheadtxtpp'>Google</p>
                    </div>
                    <div className='tabledatallforaioffender'>
                        <table className='lawinfractiondatatablediv'>

                            <thead className='offenderdatatableheaddiv'>
                                <tr className='offenderdatatableheadtr'>
                                    <td className='lawinfractiondatatableheadtd '>
                                    Law Infractions


                                    </td>

                                    <td className='lawinfractiondatatableheadtd '>
                                        Violation Frequency

                                    </td>


                                    <td className='lawinfractiondatatableheadtd '>
                                        User Impact %
                                    </td>


                                    <td className='lawinfractiondatatableheadtd '>
                                    Law Maker
                                    </td>

                                    <td className='lawinfractiondatatableheadtd '>
                                    Penalty Issued

                                    </td>
                                </tr>
                            </thead>

                            <tbody className='offenderdatatablebodydiv'>
                                <tr className='offenderdatatablebodytr'>
                                    <th className='lawinfractiondatadatatablebodytd'>
                                    Deep Fakes


                                    </th>

                                    <th className='lawinfractiondatadatatablebodytd'>
                                        Daily
                                    </th>

                                    <th className='lawinfractiondatadatatablebodytd'>
                                        100
                                    </th>

                                    <th className='lawinfractiondatadatatablebodytd'>
                                        EUC
                                    </th>

                                    <th className='lawinfractiondatadatatablebodytd'>
                                    $ FINE

                                    </th>

                                </tr>

                                <tr className='offenderdatatablebodytr'>
                                    <th className='lawinfractiondatadatatablebodytd'>
                                    Teen Suicide



                                    </th>

                                    <th className='lawinfractiondatadatatablebodytd'>
                                        Daily
                                    </th>

                                    <th className='lawinfractiondatadatatablebodytd'>
                                        100
                                    </th>

                                    <th className='lawinfractiondatadatatablebodytd'>
                                        EUC
                                    </th>

                                    <th className='lawinfractiondatadatatablebodytd'>
                                    $ FINE

                                    </th>

                                </tr>

                                <tr className='offenderdatatablebodytr'>
                                    <th className='lawinfractiondatadatatablebodytd'>
                                    Fake News



                                    </th>

                                    <th className='lawinfractiondatadatatablebodytd'>
                                        Daily
                                    </th>

                                    <th className='lawinfractiondatadatatablebodytd'>
                                        100
                                    </th>

                                    <th className='lawinfractiondatadatatablebodytd'>
                                    NATO, United Nations

                                    </th>

                                    <th className='lawinfractiondatadatatablebodytd'>
                                    DESIST WARNING


                                    </th>

                                </tr>

                                <tr className='offenderdatatablebodytr'>
                                    <th className='lawinfractiondatadatatablebodytd'>
                                    Sexualization

                                    </th>

                                    <th className='lawinfractiondatadatatablebodytd'>
                                        Daily
                                    </th>

                                    <th className='lawinfractiondatadatatablebodytd'>
                                        100
                                    </th>

                                    <th className='lawinfractiondatadatatablebodytd'>
                                        EUC
                                    </th>

                                    <th className='lawinfractiondatadatatablebodytd'>
                                    $ FINE

                                    </th>

                                </tr>


                                <tr className='offenderdatatablebodytr'>
                                    <th className='lawinfractiondatadatatablebodytd'>
                                    Prompt Generated Speech (luring)



                                    </th>

                                    <th className='lawinfractiondatadatatablebodytd'>
                                        Daily
                                    </th>

                                    <th className='lawinfractiondatadatatablebodytd'>
                                        100
                                    </th>

                                    <th className='lawinfractiondatadatatablebodytd'>
                                    EUC, NATO, USA

                                    </th>

                                    <th className='lawinfractiondatadatatablebodytd'>
                                    $ FINE


                                    </th>

                                </tr>

                                   

                              

                                
                              

                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
      </>
  )
}

export default Lawinfraction
